import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

import Header from '../../../component/common/Header';
import Sidebar from '../../../component/common/Sidebar';
import Footer from '../../../component/common/Footer';
import Loader from '../../../component/common/Loader';
import SubHeader from '../../../component/common/SubHeader';
import CONSTANT from '../../../constant';
import { onChange } from '../../../utils';
import { db } from './../../../firebase/index';

class AddFaq extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false,
            id: undefined,
            question: { name: 'question', value: '', error: '', isRequired: true },
            answer: { name: 'answer', value: '', error: '', isRequired: true },
            faqList: undefined,
            showAddField: false
        }
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                <SubHeader headerTitle="CMS Pages" seperatorTitle="" />
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderBody = () => {
        const { question, answer } = this.state;

        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="kt-portlet">
                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">
                                    Add FAQ
                                </h3>
                            </div>
                        </div>
                        <div className="kt-portlet__body">
                            <div className="mt-3">
                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <input
                                            type="text"
                                            className={question.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                            placeholder="Write question here"
                                            name={question.name}
                                            value={question.value}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 form-group">
                                        <textarea
                                            className={answer.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                            rows={5}
                                            placeholder="Write answer here"
                                            name={answer.name}
                                            value={answer.value}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-2 pr-4">
                                        <button type="button" className="btn btn-sm btn-primary btn-block" onClick={this.onPublish}>Publish</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        )
    }

    onAddNew = () => {
        this.setState({ showAddField: true })
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
    }

    onSaveDraft = (model: any) => {

    }

    onPublish = async () => {
        const { question, answer } = this.state;
        this.setState({ showLoader: true });
        const newObjModel: any = {
            content: { question: question.value, answer: answer.value },
            modifiedOn: moment().unix(),
            available: true
        };
        await db.collection(CONSTANT.collection.cmsFaq).add(newObjModel);
        window.location.href = CONSTANT.url.faq;
        this.setState({ showLoader: false });
    }
}

export default AddFaq;