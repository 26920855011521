import * as React from 'react';
import Swal from 'sweetalert2/dist/sweetalert2'
import 'sweetalert2/src/sweetalert2.scss';
import firebase from "firebase/app";
import { ToastContainer, toast } from 'react-toastify';

import { onChange, validateForm } from '../../utils';
import { db } from './../../firebase/index';
import CONSTANT from '../../constant';
import Loader from '../../component/common/Loader';
import DropzoneImg from './../common/DropzoneImg';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: detail ? detail.id : undefined,
            showLoader: false,
            title: { name: 'title', value: detail && detail.title ? detail.title : '', error: '', isRequired: true },
            description: { name: 'description', value: detail && detail.description ? detail.description : '', error: '', isRequired: true },
            currency: { name: 'currency', value: detail && detail.currency ? detail.currency : '', error: '', isRequired: true, options: [] },
            price: { name: 'price', value: detail && detail.price ? detail.price : '', error: '', isRequired: true, options: [] },
            quantity: { name: 'quantity', value: detail && detail.quantity ? detail.quantity : '', error: '', isRequired: true, options: [] },
            country: { name: 'country', value: detail && detail.country ? detail.country : '', error: '', isRequired: true, options: [] },
            imageUrl: detail && detail.images ? detail.images : [],
        }
    }

    async componentDidMount() {
        const carcaseCategoryListArray: any[] = [];
        const carcaseCategorQuerySnapshot = await db.collection(CONSTANT.collection.cmsCarcaseCategory).get();
        carcaseCategorQuerySnapshot.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            carcaseCategoryListArray.push(data);
        });
        this.setState({
            category: { ...this.state.category, options: carcaseCategoryListArray },
        });
    }

    public render() {
        const { showLoader, title, imageUrl, description, currency, price, quantity, country } = this.state;
        return (
            <React.Fragment>
                <form onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label className="col col-form-label">Item</label>
                                <div className="col-lg-12 form-group">
                                    <input
                                        type="text"
                                        className={title.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        placeholder="Enter item name"
                                        name={title.name}
                                        value={title.value}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="col col-form-label">Item Details</label>
                                <div className="col-lg-12">
                                    <textarea
                                        className={description.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        rows={6}
                                        placeholder="Write text here"
                                        name={description.name}
                                        value={description.value}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 row">
                            <div className="col-md-6">
                                <label className="col col-form-label">Price</label>
                                <div className="row ml-2" style={{ "marginLeft": "0.1rem !important" }}>
                                    <div className="col-lg-6 form-group">
                                        <select
                                            className={currency.error.length > 0 ? "form-control is-invalid" : "form-control mr-1"}
                                            name={currency.name}
                                            value={currency.value}
                                            onChange={this.onChange}>
                                            <option value="">Select currency</option>
                                            <option value="AUD">AUD</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <input
                                            type="text"
                                            className={price.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                            placeholder="Enter price"
                                            name={price.name}
                                            value={price.value}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label className="col col-form-label">Quantity</label>
                                <div className="col-lg-12">
                                    <input
                                        type="text"
                                        className={quantity.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        placeholder="Enter item name"
                                        name={quantity.name}
                                        value={quantity.value}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <label className="col col-form-label">Country Available</label>
                            <div className="col-lg-12 form-group">
                                <select
                                    className={country.error.length > 0 ? "form-control is-invalid" : "form-control mr-1"}
                                    name={country.name}
                                    value={country.value}
                                    onChange={this.onChange}>
                                    <option value="">Select country</option>
                                    <option value="Australia">Australia</option>
                                    {
                                        country.options.map(function (item: any, index: any) {
                                            return (
                                                <option key={index} id={item.id} value={item.id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {imageUrl && imageUrl.length > 0 ?
                                <div className="">
                                    <label className="col col-form-label">Item Image</label>
                                </div>
                                :
                                <div className="form-group">
                                    <label className="col col-form-label">Item Image</label>
                                    <DropzoneImg
                                        acceptFile={this.onFilesSelect}
                                        title={"Drag & Drop or Browse file or click to add file"}
                                    />
                                </div>
                            }
                            {imageUrl && imageUrl.length > 0 && imageUrl.map((item: any, index: any) => {
                                return (
                                    <div className="alert alert-success alert-dismissible fade show" role="alert" key={index}>
                                        <strong>{item}</strong>
                                        <button type="button" className="btn close" aria-label="Close" onClick={() => this.onDeleteImage(item)}>
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                )
                            })

                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <button onClick={this.props.onCancel} type="button" className="btn btn-secondary btn-sm btn-block">Cancel</button>
                        </div>
                        <div className="col-md-3" />
                        <div className="col-md-3" />
                        <div className="col-md-3">
                            <button type="submit" className="btn btn-primary btn-sm btn-sm btn-block">Save Changes</button>
                        </div>
                    </div>
                </form>
                {
                    showLoader && <Loader />
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }


    onFilesSelect = (files: any) => {
        var file = files;

        this.handleProductImageUpload(file);
    }

    handleProductImageUpload = async (fileArray: any) => {
        this.setState({ showLoader: true });

        const promises = fileArray.map((file: any) => {
            const extArray = file.name.split('.');
            const ext = extArray[extArray.length - 1];
            const originalFilename = extArray[0];
            let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
            const fileDirectory = `${CONSTANT.fileDirectory.inventory}/${fileName}`;
            const ref = firebase.storage().ref().child(`${fileDirectory}`);
            return ref
                .put(file.uploadTask)
                .then(() => ref.getDownloadURL())
        });

        await Promise.all(promises).then((fileDownloadUrls) => {
            console.log("fileDownloadUrls  => ", fileDownloadUrls);
            this.setState({ imageUrl: fileDownloadUrls, showLoader: false })
        }).catch(err => console.log(err));
        this.setState({ showLoader: false });

    };

    onDeleteImage(item: any) {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete image?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result: any) => {
            if (result.value) {
                this.deletePdf(item);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your image is safe :)',
                    'error'
                )
            }
        })
    }

    deletePdf = async (item: any) => {
        this.setState({ showLoader: true });
        try {
            var httpsReference = await firebase.storage().refFromURL(item);

            firebase.app().storage()
                .ref(CONSTANT.fileDirectory.inventory)
                .child(httpsReference.name)
                .delete()
                .then(url => {
                    let newarr = this.state.imageUrl.filter((a: any) => a !== item);
                    this.setState({ imageUrl: newarr, showLoader: false });
                    Swal.fire(
                        'Deleted!',
                        'Image has been deleted.',
                        'success'
                    )
                });
        } catch (e) {
            toast.error(e.message);
            this.setState({ showLoader: false });
        }
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const { title, imageUrl, description, currency, price, quantity, country } = this.state;

            let model: any = {
                title: title.value,
                titleFlag: title.value.toLowerCase(),
                description: description.value,
                currency: currency.value,
                price: price.value,
                quantity: quantity.value,
                country: country.value,
                images: imageUrl
            }
            if (this.state.id) {
                model.id = this.state.id;
            }
            this.props.onSubmit(model);
        }
    }

}

export default AddComponent;