import * as React from 'react';
import { onChange, validateForm } from '../../utils';
import { db } from './../../firebase/index';
import CONSTANT from '../../constant';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: detail ? detail.id : undefined,
            name: { name: 'name', value: detail ? detail.name : '', error: '', isRequired: true },
            category: { name: 'category', value: detail ? detail.category : '', error: '', isRequired: true, options: [] },
        }
    }

    async componentDidMount() {
        const carcaseCategoryListArray: any[] = [];
        const carcaseCategorQuerySnapshot = await db.collection(CONSTANT.collection.cmsCarcaseCategory).get();
        carcaseCategorQuerySnapshot.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            carcaseCategoryListArray.push(data);
        });
        this.setState({
            category: { ...this.state.category, options: carcaseCategoryListArray },
        });
    }

    public render() {
        const { name, category } = this.state;
        return (
            <form onSubmit={this.onSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <label className="col col-form-label">Name</label>
                        <div className="col-lg-12">
                            <input
                                type="text"
                                className={name.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                placeholder="Enter name"
                                name={name.name}
                                value={name.value}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 form-group">
                        <label className="col col-form-label">Category</label>
                        <div className="col-lg-12">
                            <select
                                className={category.error.length > 0 ? "form-control is-invalid" : "form-control mr-1"}
                                name={category.name}
                                value={category.value}
                                onChange={this.onChange}>
                                <option value="">Select category</option>
                                {
                                    category.options.map(function (item: any, index: any) {
                                        return (
                                            <option key={index} id={item.id} value={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <button onClick={this.props.onCancel} type="button" className="btn btn-secondary btn-sm btn-block">Cancel</button>
                    </div>
                    <div className="col-md-3" />
                    <div className="col-md-3" />
                    <div className="col-md-3">
                        <button type="submit" className="btn btn-primary btn-sm btn-sm btn-block">Save Changes</button>
                    </div>
                </div>
            </form>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            let model: any = {
                name: this.state.name.value,
                nameFlag: this.state.name.value.toLowerCase(),
                categoryId: this.state.category.value,
            }
            if (this.state.id) {
                model.id = this.state.id;
            }
            this.props.onSubmit(model);
        }
    }

}

export default AddComponent;