import * as React from "react";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaginationComponent from "react-reactstrap-pagination";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import Header from "../../../component/common/Header";
import Sidebar from "../../../component/common/Sidebar";
import Footer from "../../../component/common/Footer";
import Loader from "../../../component/common/Loader";
import SubHeader from "../../../component/common/SubHeader";
import CONSTANT from "../../../constant";
import { onChange } from "../../../utils";
import { db } from "../../../firebase/index";

class Checklist extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      list: undefined,
      page: 1,
      limit: 10,
      sort: "desc",
      count: 0,
      showLoader: false,
      keyword: { name: "keyword", value: "", error: "", isRequired: false },
    };
  }

  componentDidMount() {
    this.loadList();
  }

  public render() {
    const { showLoader } = this.state;
    return (
      <React.Fragment>
        {showLoader && <Loader />}
        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <Sidebar />
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
              id="kt_wrapper"
            >
              <Header />
              <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <SubHeader
                  headerTitle="Database Management"
                  seperatorTitle="Aus Meat Checklist Guide"
                />
                {this.renderBody()}
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          pauseOnHover
        />
      </React.Fragment>
    );
  }

  renderBody = () => {
    const { list, limit, count } = this.state;
    const ps: any = count / limit;
    const pageSize = parseInt(ps);
    return (
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="kt-portlet">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label"></div>
                <div className="kt-portlet__head-toolbar">
                  <button
                    className="btn btn-brand btn-elevate btn-icon-sm"
                    onClick={this.onAddNew}
                  >
                    Add New
                  </button>
                </div>
              </div>
              <div className="kt-portlet__body">
                <div className="kt-section mb-0">
                  <div className="kt-section__content">
                    {list !== undefined && this.renderTable()}
                  </div>
                </div>

                <div className="h-100 row align-space-between">
                  <div className="col-12 d-flex justify-content-end mt-4">
                    {count > limit && (
                      <PaginationComponent
                        classname="float-right"
                        totalItems={count}
                        pageSize={pageSize}
                        onSelect={this.handleSelected}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderTable = () => {
    const { list } = this.state;
    const columns = [
      {
        dataField: "title",
        text: "Title",
      },
      {
        dataField: "text",
        text: "Text",
        sort: false,
      },
      {
        dataField: "Edit",
        text: "",
        formatter: (
          cell: any,
          row: any,
          rowIndex: any,
          formatExtraData: any
        ) => {
          const url = CONSTANT.url.checklistDetail.replace(":id", row.id);
          return (
            <a href={url}>
              <span className="btn kt-font-brand">Edit</span>
            </a>
          );
        },
      },
      {
        dataField: "Delete",
        text: "",
        formatter: (
          cell: any,
          row: any,
          rowIndex: any,
          formatExtraData: any
        ) => {
          return (
            <button
              className="link-button"
              onClick={(e) => this.deleteChecklist(e, row)}
            >
              <span className="btn kt-font-brand">Delete</span>
            </button>
          );
        },
      },
    ];
    return (
      <React.Fragment>
        <BootstrapTable
          defaultSortDirection="asc"
          bootstrap4
          keyField="index"
          data={list}
          columns={columns}
        />
      </React.Fragment>
    );
  };

  onChange = (e: any) => {
    const value = e.target.value;

    this.setState({ limit: Number(value) }, () => {
      this.loadList();
    });
  };

  onAddNew = () => {
    window.location.href = CONSTANT.url.addChecklist;
  };

  loadList = async () => {
    const { page, limit } = this.state;
    try {
      this.setState({ showLoader: true });
      let startLimit = page > 1 ? (page - 1) * limit : page * limit;
      let users: any[] = [];

      const countSnap: any = await db
        .collection(CONSTANT.collection.dbMeatChecklist)
        .where("isActive", "==", true)
        .orderBy("modifiedOn", "asc")
        .get();

      const first = db
        .collection(CONSTANT.collection.dbMeatChecklist)
        .where("isActive", "==", true)
        .orderBy("modifiedOn", "asc")
        .limit(startLimit);
      const snapshot = await first.get();

      snapshot.forEach((doc: any) => {
        let data = doc.data();
        data.id = doc.id;
        users.push(data);
      });

      // Get the last document
      const last = snapshot.docs[snapshot.docs.length - 1];
      if (page > 1) {
        const userQuerySnapshot = await db
          .collection(CONSTANT.collection.dbMeatChecklist)
          .where("isActive", "==", true)
          .orderBy("modifiedOn", "asc")
          .startAfter(last.data().modifiedOn)
          .limit(limit)
          .get();
        users = [];
        userQuerySnapshot.forEach((doc: any) => {
          let data = doc.data();
          data.id = doc.id;
          users.push(data);
        });
      }

      this.setState({ list: users, count: countSnap.size, showLoader: false });
    } catch (err) {
      console.log(err);
    }
  };

  searchVendor = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    onChange(this, name, value, async () => {
      let result = [];
      let resultEmail = [];
      let resultMobile = [];
      let users: any[] = [];

      const userQuerySnapshot = await db
        .collection(CONSTANT.collection.users)
        .orderBy("displayName")
        .startAt(`${value}`)
        .endAt(value + "\uf8ff")
        .get();
      userQuerySnapshot.forEach((doc: any) => {
        let data = doc.data();
        data.id = doc.id;
        users.push(data);
      });
      result = users;

      if (users.length === 0) {
        let tempArr: any[] = [];
        const userQuerySnapshot = await db
          .collection(CONSTANT.collection.users)
          .orderBy("email")
          .startAt(`${value}`)
          .endAt(value + "\uf8ff")
          .get();
        userQuerySnapshot.forEach((doc: any) => {
          let data = doc.data();
          data.id = doc.id;
          resultEmail.push(data);
          tempArr.push(data);
        });
        result = tempArr;
      }

      if (resultEmail.length === 0) {
        let tempArr: any[] = [];
        const userQuerySnapshot = await db
          .collection(CONSTANT.collection.users)
          .orderBy("mobile")
          .startAt(`${value}`)
          .endAt(value + "\uf8ff")
          .get();
        userQuerySnapshot.forEach((doc: any) => {
          let data = doc.data();
          data.id = doc.id;
          resultMobile.push(data);
          tempArr.push(data);
        });
        result = tempArr;
      }

      console.log("result => ", result);
      this.setState({ list: result, count: userQuerySnapshot.size });
    });
  };

  onPageChange = (e: any) => {
    const page = parseInt(e.target.dataset.page, 10);
    this.setState({ page }, () => {
      window.scrollTo(0, 0);
      this.loadList();
    });
  };

  handleSelected = (selectedPage: any) => {
    this.setState({ page: selectedPage }, () => {
      window.scrollTo(0, 0);
      this.loadList();
    });
  };

  deleteChecklist(e: any, item: any) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete ${item.title}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result: any) => {
      if (result.value) {
        this.setState({ showLoader: true });
        // const updateMdel = { isActive: false };
        // await db.collection(CONSTANT.collection.dbMeatChecklist).doc(`${item.id}`).set(updateMdel, { merge: true });
        await db
          .collection(CONSTANT.collection.dbMeatChecklist)
          .doc(`${item.id}`)
          .delete();
        this.setState({ showLoader: false });
        this.loadList();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your meatcut is safe :)", "error");
      }
    });
  }

  onSelection = (items: any) => {};
}

export default Checklist;
