import * as React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

import Header from "../../../component/common/Header";
import Sidebar from "../../../component/common/Sidebar";
import Footer from "../../../component/common/Footer";
import Loader from "../../../component/common/Loader";
import SubHeader from "../../../component/common/SubHeader";
import CONSTANT from "../../../constant";
import { db } from "./../../../firebase/index";

class Faq extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      showLoader: false,
      faqList: undefined,
    };
  }

  componentDidMount() {
    this.loadList();
  }

  public render() {
    const { showLoader } = this.state;
    return (
      <React.Fragment>
        {showLoader && <Loader />}
        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <Sidebar />
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
              id="kt_wrapper"
            >
              <Header />
              <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <SubHeader headerTitle="CMS Pages" seperatorTitle="" />
                {this.renderBody()}
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          pauseOnHover
        />
      </React.Fragment>
    );
  }

  renderBody = () => {
    const { faqList } = this.state;
    return (
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div className="row">
          <div className="kt-portlet">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">FAQs</h3>
              </div>
              <div className="kt-portlet__head-toolbar">
                <button
                  className="btn btn-brand btn-elevate btn-icon-sm"
                  onClick={this.onAddNew}
                >
                  Add New
                </button>
              </div>
            </div>
            <div className="kt-portlet__body">
              {faqList &&
                faqList.map((item: any, index: any) => {
                  const url = CONSTANT.url.editFaq.replace(":id", item.id);
                  return (
                    <div className="p-4 mb-2 border" key={index}>
                      <div className="row col-12">
                        <div className="col-1">
                          <i className="fa fa-circle mr-5"></i>
                        </div>
                        <div className="col-10 ">
                          <label className="font-weight-bold">
                            <h5>{item.content.question}</h5>
                          </label>
                        </div>
                        <div className="col-1 row">
                          <div className="col-6">
                            <button className="btn btn-sm pull-right">
                              <a href={url}>
                                <i className="fa fa-edit"></i>
                                {item.name}
                              </a>
                            </button>
                          </div>
                          <div className="col-6">
                            <button
                              className="btn btn-sm pull-right"
                              onClick={() => this.onDeleteItem(item)}
                            >
                              <i className="fa fa-trash"></i>
                              {item.name}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row col-12">
                        <div className="col-1" />
                        <div className="col-10">
                          <span className="text-muted font-weight-bold">
                            {item.content.answer}
                          </span>
                        </div>
                        <div className="col-1" />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  onAddNew = () => {
    window.location.href = CONSTANT.url.addFaq;
  };

  loadList = async () => {
    this.setState({ showLoader: true });
    const userQuerySnapshot = await db
      .collection(CONSTANT.collection.cmsFaq)
      .orderBy("modifiedOn", "asc")
      .get();
    const list: any[] = [];
    userQuerySnapshot.forEach((doc: any) => {
      let data = doc.data();
      data.id = doc.id;
      list.push(data);
    });

    this.setState({ showLoader: false, faqList: list });
  };

  onDeleteItem = (item: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete ${item.content.question}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result: any) => {
      if (result.value) {
        this.setState({ showLoader: true });
        await db
          .collection(CONSTANT.collection.cmsFaq)
          .doc(`${item.id}`)
          .delete();
        toast.success("Success");
        this.loadList();
        this.setState({ showLoader: false });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your support is safe :)", "error");
      }
    });
  };
}

export default Faq;
