import * as React from 'react';
import firebase from "firebase/app";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2/dist/sweetalert2'
import 'sweetalert2/src/sweetalert2.scss';

import Loader from '../../component/common/Loader';
import Dropzone from './../common/DropzoneModel';
import DropzoneImg from './../common/DropzoneImg';
import { onChange, validateForm, getStorage } from './../../utils';
import CONSTANT from '../../constant';
import { db } from './../../firebase/index';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: props.id,
            detail,
            currentUserId: undefined,
            showLoader: false,

            category: { name: 'category', value: detail && detail.category ? detail.category : '', error: '', isRequired: true, options: [] },
            meatcut: { name: 'meatcut', value: detail && detail.meatcut ? detail.meatcut : '', error: '', isRequired: true, options: [] },
            meatcutCode: { name: 'meatcutCode', value: detail && detail.meatcutCode ? detail.meatcutCode : '', error: '', isRequired: true, options: [] },

            modelUrl: detail && detail.modelUrl ? detail.modelUrl : undefined,
            modelFileName: detail && detail.modelFileName ? detail.modelFileName : undefined,

            photoRefUrl: detail && detail.photoRefUrl ? detail.photoRefUrl : undefined,
            photoRefFileName: detail && detail.photoRefFileName ? detail.photoRefFileName : undefined,

            carcaseUrl: detail && detail.carcaseUrl ? detail.carcaseUrl : undefined,
            carcaseFileName: detail && detail.carcaseFileName ? detail.carcaseFileName : undefined,
        }
    }

    componentDidMount() {
        const ud = getStorage(CONSTANT.keys.userDetail);
        this.setState({ currentUserId: ud.uid });
        this.loadList();
    }

    public render() {
        const {
            showLoader, category, meatcut, meatcutCode, modelUrl, photoRefUrl, carcaseUrl
        } = this.state;
        return (
            <React.Fragment>
                <form onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-md-4 form-group">
                            <label className="col col-form-label">Category</label>
                            <div className="col-lg-12 form-group">
                                <select
                                    className={category.error.length > 0 ? "form-control is-invalid" : "form-control mr-1"}
                                    name={category.name}
                                    value={category.value}
                                    onChange={this.onChange}>
                                    <option value="">Select category</option>
                                    {
                                        category.options.map(function (item: any, index: any) {
                                            return (
                                                <option key={index} id={item.id} value={item.name}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4 form-group">
                            <label className="col col-form-label">Meatcut</label>
                            <div className="col-lg-12 form-group">
                                <select
                                    className={meatcut.error.length > 0 ? "form-control is-invalid" : "form-control mr-1"}
                                    name={meatcut.name}
                                    value={meatcut.value}
                                    onChange={this.onChange}>
                                    <option value="">Select meatcut</option>
                                    {
                                        meatcut.options.map(function (item: any, index: any) {
                                            return (
                                                <option key={index} id={item.id} value={item.meatcutName}>{item.meatcutName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4 form-group">
                            <label className="col col-form-label">Meatcut Code</label>
                            <div className="col-lg-12 form-group">
                                <select
                                    className={meatcutCode.error.length > 0 ? "form-control is-invalid" : "form-control mr-1"}
                                    name={meatcutCode.name}
                                    value={meatcutCode.value}
                                    onChange={this.onChange}>
                                    <option value="">Select meatcut code</option>
                                    {
                                        meatcutCode.options.map(function (item: any, index: any) {
                                            return (
                                                <option key={index} id={item.id} value={item.meatcutCode}>{item.meatcutCode}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-4">
                            {modelUrl ?
                                <div className="">
                                    <label className="col col-form-label">Add 3d model</label>
                                </div>
                                :
                                <div className="form-group">
                                    <label className="col col-form-label">Add 3d model</label>
                                    <Dropzone
                                        acceptFile={this.onFiles3dModel}
                                        title={"Drag & Drop or Browse file"}
                                    />
                                </div>
                            }
                            {modelUrl &&
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    <strong>{modelUrl}</strong>
                                    <button type="button" className="btn close" aria-label="Close" onClick={this.onDelete3dModel}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                            }
                        </div>
                        <div className="col-md-4">
                            {photoRefUrl ?
                                <div className="">
                                    <label className="col col-form-label">Add Photo Reference</label>
                                </div>
                                :
                                <div className="form-group">
                                    <label className="col col-form-label">Add Photo Reference</label>
                                    <DropzoneImg
                                        acceptFile={this.onPhotoRefFileAdded}
                                        title={"Drag & Drop or Browse file"}
                                    />
                                </div>
                            }
                            {photoRefUrl &&
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    <strong>{photoRefUrl}</strong>
                                    <button type="button" className="btn close" aria-label="Close" onClick={this.onPhotoRefDelete}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                            }
                        </div>
                        <div className="col-md-4">
                            {carcaseUrl ?
                                <div className="">
                                    <label className="col col-form-label">Add Carcass Reference</label>
                                </div>
                                :
                                <div className="form-group">
                                    <label className="col col-form-label">Add Carcass Reference</label>
                                    <Dropzone
                                        acceptFile={this.onCarcaseFileAdded}
                                        title={"Drag & Drop or Browse file"}
                                    />
                                </div>
                            }
                            {carcaseUrl &&
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    <strong>{carcaseUrl}</strong>
                                    <button type="button" className="btn close" aria-label="Close" onClick={this.onCarcaseDelete}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-10" />
                        <div className="col-lg-2 mr-0">
                            <button type="submit" className="btn btn-primary btn-sm btn-block">Save</button>
                        </div>
                    </div>

                </form>
                {
                    showLoader && <Loader />
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;

        if (name === this.state.meatcut.name) {
            let id = e.target[e.target.selectedIndex].id
            this.getMeactCodeByIdList(id);
        }

        onChange(this, name, value);
    }

    typeaheadOnChange = (name: string, e: any) => {
        let value = e;
        if (e.length > 0 && e[0].customOption) {
            value = [{ name: e[0].name }];
        }
        onChange(this, name, value);
    }

    loadList = async () => {
        this.setState({ showLoader: true });
        const categoryListArray: any[] = [];
        const meatcutListArray: any[] = [];

        const categoryQuerySnapshot = await db.collection(CONSTANT.collection.cmsCategoryMuscle).where("isActive", "==", true).orderBy('modifiedOn', 'asc').get();
        categoryQuerySnapshot.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            categoryListArray.push(data);
        });

        const meactcutQuerySnapshot = await db.collection(CONSTANT.collection.dbMeatcut).where("isActive", "==", true).orderBy('modifiedOn', 'asc').get();
        meactcutQuerySnapshot.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            meatcutListArray.push(data);
        });

        this.setState({
            showLoader: false,
            category: { ...this.state.category, options: categoryListArray },
            meatcut: { ...this.state.meatcut, options: meatcutListArray },
        });

        if (this.state.meatcut.value) {
            const filterArr = meatcutListArray.filter((i: any) => i.meatcutName === this.state.meatcut.value);
            if (filterArr.length > 0) {
                this.getMeactCodeByIdList(filterArr[0].id);
            }
        }

    }

    getMeactCodeByIdList = async (id: any) => {
        this.setState({ showLoader: true });
        const meatcutListArray: any[] = [];
        const user = await db.collection(CONSTANT.collection.dbMeatcut).doc(`${id}`).get();
        let userData = user.data();
        meatcutListArray.push(userData);

        this.setState({
            showLoader: false,
            meatcutCode: { ...this.state.meatcutCode, options: meatcutListArray },
        });

    }

    onFiles3dModel = (files: any) => {
        var file = files[0];
        this.handleModelUpload(file);
    }

    handleModelUpload = (file: any) => {
        this.setState({ showLoader: true });

        const extArray = file.name.split('.');
        const ext = extArray[extArray.length - 1];
        const originalFilename = extArray[0];
        let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
        const fileDirectory = `${CONSTANT.fileDirectory.model3d}/${fileName}`;

        const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

        uploadTask.on("state_changed", (snapshot: any) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.setState({ progress });
        },
            (error: any) => {
                console.log(error);
            },
            () => {
                firebase.app().storage()
                    .ref(CONSTANT.fileDirectory.model3d)
                    .child(fileName)
                    .getDownloadURL()
                    .then(url => {
                        this.setState({ modelUrl: url, modelFileName: fileName, showLoader: false });
                    });
            }
        );
    };

    onDelete3dModel = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete model?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result: any) => {
            if (result.value) {
                this.delete3dModel();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your catalogue is safe :)',
                    'error'
                )
            }
        })
    }

    delete3dModel = () => {
        this.setState({ showLoader: true });
        firebase.app().storage()
            .ref(CONSTANT.fileDirectory.model3d)
            .child(this.state.modelFileName)
            .delete()
            .then(url => {
                this.setState({ modelFileName: "", modelUrl: "", showLoader: false });
                Swal.fire(
                    'Deleted!',
                    'Model has been deleted.',
                    'success'
                )
            });
    }

    onPhotoRefFileAdded = (e: any) => {
        var file = e[0];
        if (file) {
            this.handlePhotoRefUpload(file);
        }
    }

    handlePhotoRefUpload = (file: any) => {
        this.setState({ showLoader: true });

        const extArray = file.name.split('.');
        const ext = extArray[extArray.length - 1];
        const originalFilename = extArray[0];
        let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
        const fileDirectory = `${CONSTANT.fileDirectory.photoRef}/${fileName}`;

        const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

        uploadTask.on("state_changed", (snapshot: any) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.setState({ progress });
        },
            (error: any) => {
                console.log(error);
            },
            () => {
                firebase.app().storage()
                    .ref(CONSTANT.fileDirectory.photoRef)
                    .child(fileName)
                    .getDownloadURL()
                    .then(url => {
                        this.setState({ photoRefUrl: url, photoRefFileName: fileName, showLoader: false });
                    });
            }
        );
    };

    onPhotoRefDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete PhotoRef?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result: any) => {
            if (result.value) {
                this.deletePhoRef();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your PhotoRef is safe :)',
                    'error'
                )
            }
        })
    }

    deletePhoRef = () => {
        this.setState({ showLoader: true });
        firebase.app().storage()
            .ref(CONSTANT.fileDirectory.photoRef)
            .child(this.state.photoRefFileName)
            .delete()
            .then(url => {
                this.setState({ photoRefFileName: "", photoRefUrl: "", showLoader: false });
                Swal.fire(
                    'Deleted!',
                    'PhotoRef has been deleted.',
                    'success'
                )
            });
    }

    onCarcaseFileAdded = (e: any) => {
        var file = e[0];
        if (file) {
            this.handleCarcaseUpload(file);
        }
    }

    handleCarcaseUpload = (file: any) => {
        this.setState({ showLoader: true });

        const extArray = file.name.split('.');
        const ext = extArray[extArray.length - 1];
        const originalFilename = extArray[0];
        let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
        const fileDirectory = `${CONSTANT.fileDirectory.carcase}/${fileName}`;

        const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

        uploadTask.on("state_changed", (snapshot: any) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.setState({ progress });
        },
            (error: any) => {
                console.log(error);
            },
            () => {
                firebase.app().storage()
                    .ref(CONSTANT.fileDirectory.carcase)
                    .child(fileName)
                    .getDownloadURL()
                    .then(url => {
                        this.setState({ carcaseUrl: url, carcaseFileName: fileName, showLoader: false });
                    });
            }
        );
    };

    onCarcaseDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete carcase?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result: any) => {
            if (result.value) {
                this.deleteCarcase();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your carcase is safe :)',
                    'error'
                )
            }
        })
    }

    deleteCarcase = () => {
        this.setState({ showLoader: true });
        firebase.app().storage()
            .ref(CONSTANT.fileDirectory.carcase)
            .child(this.state.carcaseFileName)
            .delete()
            .then(url => {
                this.setState({ carcaseFileName: "", carcaseUrl: "", showLoader: false });
                Swal.fire(
                    'Deleted!',
                    'Carcase has been deleted.',
                    'success'
                )
            });
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {

            const {
                category, meatcut, meatcutCode, modelUrl, modelFileName, photoRefUrl, photoRefFileName, carcaseUrl, carcaseFileName
            } = this.state;

            if (!modelUrl) return toast.error("Please upload 3d model.");
            if (!photoRefUrl) return toast.error("Please upload photoRef.");
            if (!carcaseUrl) return toast.error("Please upload carcase.");

            const model: any = {
                category: category.value,
                meatcut: meatcut.value,
                meatcutCode: meatcutCode.value,
                modelUrl: modelUrl,
                modelFileName: modelFileName,
                photoRefUrl: photoRefUrl,
                photoRefFileName: photoRefFileName,
                carcaseUrl: carcaseUrl,
                carcaseFileName: carcaseFileName
            };
            if (this.state.id) {
                model.id = this.state.id;
            }
            this.props.onSubmit(model);
        }

    }

}

export default AddComponent;