import * as React from 'react';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import CONSTANT from './../../constant';
import Shimmer from '../../component/common/Shimmer';
import { db } from './../../firebase/index';
const defaultProfile = require("./../../assets/media/users/default.jpg")

class UserDetail extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: props.match.params.id,
            list: undefined,
            detail: [],
            showLoader: false
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { detail } = this.state;
        return (
            <div className="kt-grid kt-grid--hor kt-grid--root">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                    <Sidebar />
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                        <Header />
                        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                            {this.renderHeader()}
                            {detail ? this.renderBody() : <Shimmer />}
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        )
    }

    renderHeader = () => {
        const { detail } = this.state;
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            User Management
                        </h3>

                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>

                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {detail && detail.displayName ? detail.displayName : `${detail && detail.firstName ? detail.firstName : ''} ${detail && detail.lastName ? detail.lastName : ''}`}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { detail } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="card card-custom gutter-b card-stretch">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xl-8 col-lg-8">
                                        <div className="row">

                                            <div className="col-lg-4">
                                                <div className="mt-3">
                                                    <div className="example-preview" id="kt_blockui_content">
                                                        {detail && detail.profileUrl ?
                                                            <img src={detail.profileUrl} className="img-fluid" alt="img" height="200" width="200" />
                                                            : <img src={defaultProfile} className="img-fluid" alt="img" height="200" width="200" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-8">
                                                <div className="row mt-3">
                                                    <label className="col-12 col-form-label font-weight-bold">
                                                        <h3 className="mb-3">{detail && detail.displayName ? detail.displayName : `${detail && detail.firstName ? detail.firstName : ''} ${detail && detail.lastName ? detail.lastName : ''}`}</h3>
                                                    </label>
                                                </div>
                                                <div className="row">
                                                    <label className="col-4 col-form-label font-weight-bold">Phone no.</label>
                                                    <label className="col-8 col-form-label">{detail && detail.mobile ? detail.mobile : '-'}</label>
                                                </div>
                                                <div className="row">
                                                    <label className="col-4 col-form-label font-weight-bold">Email</label>
                                                    <label className="col-8 col-form-label">{detail && detail.email ? detail.email : '-'}</label>
                                                </div>
                                                <div className="row">
                                                    <label className="col-4 col-form-label font-weight-bold">Country</label>
                                                    <label className="col-8 col-form-label">{detail && detail.country ? detail.country : '-'}</label>
                                                </div>
                                                <div className="row">
                                                    <label className="col-4 col-form-label font-weight-bold">Company</label>
                                                    <label className="col-8 col-form-label">{detail && detail.company ? detail.company : '-'}</label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    loadList = async () => {
        this.setState({ showLoader: true });
        const { id } = this.state;

        const user = await db.collection(CONSTANT.collection.users).doc(`${id}`).get();
        // if (!user.exists) return handleFailure(res, 'User not found');

        let userData = user.data();

        this.setState({
            detail: userData
        });
    }

    onPageChange = (e: any) => {
        const page = parseInt(e.target.dataset.page, 10);
        this.setState({ page }, () => {
            window.scrollTo(0, 0);
            this.loadList();
        });
    }
}

export default UserDetail;