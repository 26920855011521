import React from 'react';

const ComponentName = () => {
    const dt = new Date();
    return (
        <div className="kt-footer  kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer">
            <div className="kt-container  kt-container--fluid ">
                <div className="kt-footer__copyright">
                    Copyright&nbsp;©&nbsp;{dt.getFullYear()}&nbsp; <b><span className="kt-link">Aus Meat</span></b>. All rights reserved.
                </div>
            </div>
        </div>
    )
}

export default ComponentName;