import * as React from 'react';
import firebase from "firebase/app";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2/dist/sweetalert2'
import 'sweetalert2/src/sweetalert2.scss';

import Loader from '../../component/common/Loader';
import { onChange, validateForm } from '../../utils';
import Dropzone from './../common/DropzoneModel';
import DropzoneImg from './../common/DropzoneImg';
import CONSTANT from '../../constant';
import { db } from './../../firebase/index';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: detail ? detail.id : undefined,
            showLoader: false,
            name: { name: 'name', value: detail ? detail.name : '', error: '', isRequired: true },
            animalName: { name: 'animalName', value: detail && detail.meatProductId ? detail.meatProductId : '', error: '', isRequired: true, options: [] },
            modelUrl: detail && detail.modelUrl ? detail.modelUrl : undefined,
            textureUrl: detail && detail.textureUrl ? detail.textureUrl : undefined,
            intersection: detail && detail.intersection ? detail.intersection : undefined,
        }
    }

    async componentDidMount() {
        const animalListArray: any[] = [];
        const animalQuerySnapshot = await db.collection(CONSTANT.collection.cmsCategoryAnimal).where("isActive", "==", true).get();
        animalQuerySnapshot.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            animalListArray.push(data);
        });

        this.setState({
            animalName: { ...this.state.animalName, options: animalListArray },
        });
    }

    public render() {
        const { name, animalName, modelUrl, textureUrl, showLoader } = this.state;
        return (
            <React.Fragment>
                <form onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-lg-6 form-group">
                            <label className="col col-form-label">Model Name</label>
                            <input
                                type="text"
                                className={name.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                placeholder="Enter model name"
                                name={name.name}
                                value={name.value}
                                onChange={this.onChange}
                            />
                        </div>
                        <div className="col-lg-6 form-group">
                            <label className="col col-form-label">Meat Product</label>
                            <select
                                className={animalName.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                name={animalName.name}
                                value={animalName.value}
                                onChange={this.onChange}
                            >
                                <option value="">Select meat product</option>
                                {
                                    animalName.options.map(function (item: any, ind: any) {
                                        return (
                                            <option key={ind} id={item.id} value={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="row mb-2">

                        <div className="col-md-6">
                            {modelUrl ?
                                <div className="">
                                    <label className="col col-form-label">Add 3d model</label>
                                    {/* <embed src={modelUrl} type="application/pdf" width="100%" height="250px" /> */}
                                </div>
                                :
                                <div className="form-group">
                                    <label className="col col-form-label">Add 3d model</label>
                                    <Dropzone
                                        acceptFile={this.onFiles3dModel}
                                        title={"Drag & Drop or Browse file"}
                                    />
                                </div>
                            }
                            {modelUrl &&
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    <strong>{modelUrl}</strong>
                                    <button type="button" className="btn close" aria-label="Close" onClick={this.onDelete3dModel}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                            }
                        </div>
                        <div className="col-md-6">
                            {textureUrl ?
                                <div className="">
                                    <label className="col col-form-label">Add img texture Reference</label>
                                    {/* <embed src={textureUrl} type="application/pdf" width="100%" height="250px" /> */}
                                </div>
                                :
                                <div className="form-group">
                                    <label className="col col-form-label">Add img texture Reference</label>
                                    <DropzoneImg
                                        acceptFile={this.onCarcaseFileAdded}
                                        title={"Drag & Drop or Browse file"}
                                    />
                                </div>
                            }
                            {textureUrl &&
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    <strong>{textureUrl}</strong>
                                    <button type="button" className="btn close" aria-label="Close" onClick={this.onCarcaseDelete}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <button onClick={this.props.onCancel} type="button" className="btn btn-secondary btn-sm btn-block">Cancel</button>
                        </div>
                        <div className="col-md-3" />
                        <div className="col-md-3" />
                        <div className="col-md-3">
                            <button type="submit" className="btn btn-primary btn-sm btn-sm btn-block">Save Changes</button>
                        </div>
                    </div>
                </form>
                {
                    showLoader && <Loader />
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    onFiles3dModel = (files: any) => {
        var file = files[0];
        this.handleModelUpload(file);
    }

    handleModelUpload = (file: any) => {
        this.setState({ showLoader: true });

        const extArray = file.name.split('.');
        const ext = extArray[extArray.length - 1];
        const originalFilename = extArray[0];
        let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
        const fileDirectory = `${CONSTANT.fileDirectory.model3d}/${fileName}`;

        const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

        uploadTask.on("state_changed", (snapshot: any) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.setState({ progress });
        },
            (error: any) => {
                console.log(error);
            },
            () => {
                firebase.app().storage()
                    .ref(CONSTANT.fileDirectory.model3d)
                    .child(fileName)
                    .getDownloadURL()
                    .then(url => {
                        this.setState({ modelUrl: url, showLoader: false });
                    });
            }
        );
    };

    onDelete3dModel = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete model?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result: any) => {
            if (result.value) {
                this.delete3dModel();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your catalogue is safe :)',
                    'error'
                )
            }
        })
    }

    delete3dModel = async () => {
        this.setState({ showLoader: true });
        try {
            let httpsReference = await firebase.storage().refFromURL(this.state.modelUrl);

            firebase.app().storage()
                .ref(CONSTANT.fileDirectory.model3d)
                .child(httpsReference.name)
                .delete()
                .then(url => {
                    this.setState({ modelUrl: undefined, showLoader: false });
                    Swal.fire(
                        'Deleted!',
                        'Model has been deleted.',
                        'success'
                    )
                });
        } catch (e) {
            toast.error(e.message);
            this.setState({ showLoader: false });
        }
    }

    onCarcaseFileAdded = (e: any) => {
        var file = e[0];
        if (file) {
            this.handleCarcaseUpload(file);
        }
    }

    handleCarcaseUpload = (file: any) => {
        this.setState({ showLoader: true });

        const extArray = file.name.split('.');
        const ext = extArray[extArray.length - 1];
        const originalFilename = extArray[0];
        let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
        const fileDirectory = `${CONSTANT.fileDirectory.carcase}/${fileName}`;

        const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

        uploadTask.on("state_changed", (snapshot: any) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.setState({ progress });
        },
            (error: any) => {
                console.log(error);
            },
            () => {
                firebase.app().storage()
                    .ref(CONSTANT.fileDirectory.carcase)
                    .child(fileName)
                    .getDownloadURL()
                    .then(url => {
                        this.setState({ textureUrl: url, showLoader: false });
                    });
            }
        );
    };

    onCarcaseDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete carcase?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result: any) => {
            if (result.value) {
                this.deleteCarcase();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your carcase is safe :)',
                    'error'
                )
            }
        })
    }

    deleteCarcase = async () => {
        this.setState({ showLoader: true });
        try {
            let httpsReference = await firebase.storage().refFromURL(this.state.textureUrl);

            firebase.app().storage()
                .ref(CONSTANT.fileDirectory.carcase)
                .child(httpsReference.name)
                .delete()
                .then(url => {
                    this.setState({ textureUrl: undefined, showLoader: false });
                    Swal.fire(
                        'Deleted!',
                        'Carcase has been deleted.',
                        'success'
                    )
                });
        } catch (e) {
            toast.error(e.message);
            this.setState({ showLoader: false });
        }
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            if (!this.state.modelUrl) return toast.error("Please upload 3d model.");
            if (!this.state.textureUrl) return toast.error("Please upload texture image.");

            let model: any = {
                name: this.state.name.value,
                nameFlag: this.state.name.value.toLowerCase(),
                modelUrl: this.state.modelUrl,
                textureUrl: this.state.textureUrl,
                meatProductId: this.state.animalName.value
            }
            if (this.state.id) {
                model.id = this.state.id;
            }
            this.props.onSubmit(model);
        }
    }

}

export default AddComponent;