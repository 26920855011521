// import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import CONSTANT from './constant';
// import { getStorage } from './utils';

// auth routes
import Login from './container/auth/Login';
// import Register from './container/auth/Register';
import ForgotPassword from './container/auth/ForgotPassword';
import ChangePassword from './container/auth/ChangePassword';
import Logout from './container/auth/Logout';
// auth routes

import Dashboard from './container/Dashboard';

/* User route */
import UserList from './container/user-management/List';
import UserDetail from './container/user-management/Detail';
/* User route */

/* User route */
import MeatcutList from './container/db-management/meatcut/List';
import AddMeatcut from './container/db-management/meatcut/Add';
import EditMeatcut from './container/db-management/meatcut/Edit';
import MuscleList from './container/db-management/muscle/List';
import AddMuscle from './container/db-management/muscle/Add';
import EditMuscle from './container/db-management/muscle/Edit';
import DefinitionList from './container/db-management/definition/List';
import AddDefinition from './container/db-management/definition/Add';
import EditDefinition from './container/db-management/definition/Edit';
import PackagingList from './container/db-management/packaging/List';
import AddPackaging from './container/db-management/packaging/Add';
import EditPackaging from './container/db-management/packaging/Edit';
import Checklist from './container/db-management/checklist/List';
import AddChecklist from './container/db-management/checklist/Add';
import EditChecklist from './container/db-management/checklist/Edit';
/* User route */

/* User route */
import PDFManagement from './container/pdf-management/List';
/* User route */

/* User route */
import InventoryManagement from './container/inventory-management/List';
/* User route */

/* User route */
import SalesManagement from './container/sales-management/List';
/* User route */

/* Profile route */
import MyProfile from './container/my-profile/Profile';
import ProfileEdit from './container/my-profile/ProfileEdit';
/* Profile route */

/* CMS pages route */
import CarcaseList from './container/cms-pages/carcase/List';
import AddCarcase from './container/cms-pages/carcase/Add';
import EditCarcase from './container/cms-pages/carcase/Edit';
import AboutUs from './container/cms-pages/about-aus-meat/List';
import AddAboutUs from './container/cms-pages/about-aus-meat/Add';
import EditAboutUs from './container/cms-pages/about-aus-meat/Edit';
import TermCondition from './container/cms-pages/Terms';
import CategoryManagement from './container/cms-pages/Category';
import FaqList from './container/cms-pages/faq/List';
import AddFaq from './container/cms-pages/faq/Add';
import EditFaq from './container/cms-pages/faq/Edit';
import FoodIntegrityList from './container/cms-pages/food-integrity/List';
import AddFoodIntegrity from './container/cms-pages/food-integrity/Add';
import EditFoodIntegrity from './container/cms-pages/food-integrity/Edit';
import MarketingResearchList from './container/cms-pages/marketing-research/List';
import AddMarketingResearch from './container/cms-pages/marketing-research/Add';
import EditMarketingResearch from './container/cms-pages/marketing-research/Edit';
/* CMS pages route */

/* 3d model route */
import ThreedModel from './container/3d-model/List';
/* 3d model route */

/* misc route */
import FourZeroFour from './container/error/FourZeroFour';
/* misc route */

// const PrivateRoute = (props: any) => {
//     const { user, logout }: any = useContext(UserContext);
//     console.log(user)
//     if (user && user.displayName) {
//         return (
//             <Fragment>
//                 <Route exact={true} path={props.path} component={props.component} />
//             </Fragment>
//         )
//     } else {
//         return (
//             <Login />
//         )
//     }
// }

const Router = (props: any) => {
    return (
        <Switch>
            {/* auth routes */}
            <Route exact={true} path={CONSTANT.url.login} component={Login} />
            {/* <Route exact={true} path={CONSTANT.url.register} component={Register} /> */}
            <Route exact={true} path={CONSTANT.url.forgotPassword} component={ForgotPassword} />
            <Route exact={true} path={CONSTANT.url.changePassword} component={ChangePassword} />
            <Route exact={true} path={CONSTANT.url.logout} component={Logout} />
            {/* auth routes */}

            <Route exact={true} path={CONSTANT.url.dashboard} component={Dashboard} />

            {/* user management routes */}
            <Route exact={true} path={CONSTANT.url.user} component={UserList} />
            <Route exact={true} path={CONSTANT.url.userDetail} component={UserDetail} />
            {/* user management routes */}

            {/* database management routes */}
            <Route exact={true} path={CONSTANT.url.meatcutList} component={MeatcutList} />
            <Route exact={true} path={CONSTANT.url.addMeatcut} component={AddMeatcut} />
            <Route exact={true} path={CONSTANT.url.meatcutDetail} component={EditMeatcut} />

            <Route exact={true} path={CONSTANT.url.muscleList} component={MuscleList} />
            <Route exact={true} path={CONSTANT.url.addMuscle} component={AddMuscle} />
            <Route exact={true} path={CONSTANT.url.muscleDetail} component={EditMuscle} />

            <Route exact={true} path={CONSTANT.url.definitionList} component={DefinitionList} />
            <Route exact={true} path={CONSTANT.url.addDefinition} component={AddDefinition} />
            <Route exact={true} path={CONSTANT.url.definitionDetail} component={EditDefinition} />

            <Route exact={true} path={CONSTANT.url.packagingList} component={PackagingList} />
            <Route exact={true} path={CONSTANT.url.addPackaging} component={AddPackaging} />
            <Route exact={true} path={CONSTANT.url.packagingDetail} component={EditPackaging} />

            <Route exact={true} path={CONSTANT.url.checklist} component={Checklist} />
            <Route exact={true} path={CONSTANT.url.addChecklist} component={AddChecklist} />
            <Route exact={true} path={CONSTANT.url.checklistDetail} component={EditChecklist} />

            {/* database management routes */}

            {/* Pdf management routes */}
            <Route exact={true} path={CONSTANT.url.pdfManagement} component={PDFManagement} />
            {/* Pdf management routes */}

            {/* Inventory management routes */}
            <Route exact={true} path={CONSTANT.url.inventoryManagement} component={InventoryManagement} />
            {/* Inventory management routes */}

            {/* Sales management routes */}
            <Route exact={true} path={CONSTANT.url.salesManagement} component={SalesManagement} />
            {/* Sales management routes */}

            {/* profile routes */}
            <Route exact={true} path={CONSTANT.url.profile} component={MyProfile} />
            <Route exact={true} path={CONSTANT.url.profileEdit} component={ProfileEdit} />
            {/* profile routes */}

            {/* CMS pages routes */}
            <Route exact={true} path={CONSTANT.url.carcaseList} component={CarcaseList} />
            <Route exact={true} path={CONSTANT.url.addCarcase} component={AddCarcase} />
            <Route exact={true} path={CONSTANT.url.editCarcase} component={EditCarcase} />
            <Route exact={true} path={CONSTANT.url.aboutUs} component={AboutUs} />
            <Route exact={true} path={CONSTANT.url.addAboutUs} component={AddAboutUs} />
            <Route exact={true} path={CONSTANT.url.editAboutUs} component={EditAboutUs} />
            <Route exact={true} path={CONSTANT.url.termCondition} component={TermCondition} />
            <Route exact={true} path={CONSTANT.url.categories} component={CategoryManagement} />
            <Route exact={true} path={CONSTANT.url.faq} component={FaqList} />
            <Route exact={true} path={CONSTANT.url.addFaq} component={AddFaq} />
            <Route exact={true} path={CONSTANT.url.editFaq} component={EditFaq} />
            <Route exact={true} path={CONSTANT.url.foodIntegrity} component={FoodIntegrityList} />
            <Route exact={true} path={CONSTANT.url.addFoodIntegrity} component={AddFoodIntegrity} />
            <Route exact={true} path={CONSTANT.url.editFoodIntegrity} component={EditFoodIntegrity} />
            <Route exact={true} path={CONSTANT.url.marketingResearch} component={MarketingResearchList} />
            <Route exact={true} path={CONSTANT.url.addMarketingResearch} component={AddMarketingResearch} />
            <Route exact={true} path={CONSTANT.url.editMarketingResearch} component={EditMarketingResearch} />
            {/* CMS pages routes */}

            <Route exact={true} path={CONSTANT.url.threedModel} component={ThreedModel} />

            <Route component={FourZeroFour} />
        </Switch>
    );
}

export default Router;