import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

import Header from '../../../component/common/Header';
import Sidebar from '../../../component/common/Sidebar';
import Footer from '../../../component/common/Footer';
import Loader from '../../../component/common/Loader';
import SubHeader from '../../../component/common/SubHeader';
import AddMeatcutDetail from './../../../component/db-management/MeatcutDetail';
import CONSTANT from '../../../constant';
import { db } from '../../../firebase/index';

class EditMeatcut extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            meatcutId: props.match.params.id,
            showLoader: false,
            detail: undefined
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                <SubHeader headerTitle="Database Management" seperatorTitle="Meat Product" />
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderBody = () => {
        const { detail, meatcutId } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="kt-portlet">
                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">
                                    Edit Meat Product
                                </h3>
                            </div>
                        </div>
                        <div className="kt-portlet__body">
                            {detail && Object.keys(detail).length > 0 &&
                                <AddMeatcutDetail
                                    onSubmit={this.onSubmit}
                                    id={meatcutId}
                                    detail={detail}
                                />
                            }
                            {detail && Object.keys(detail).length === 0 &&
                                <div className="col-lg-12 text-center">
                                    <span className="text-center">No Catalogue Found</span>
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    loadList = async () => {
        const { meatcutId } = this.state;
        this.setState({ showLoader: true });
        const document = await db.collection(CONSTANT.collection.dbMeatcut).doc(`${meatcutId}`).get();
        if (!document.exists) return toast.error("Meatcut not found.");

        let meatcutData = document.data();
        if (meatcutData) {
            // onChange(this, this.state.question.name, faqData.content.question);
            // onChange(this, this.state.answer.name, faqData.content.answer);
        }
        this.setState({ detail: meatcutData, showLoader: false });
    }

    onSubmit = async(model: any) => {
        const { meatcutId } = this.state;
        model.modifiedOn = moment().unix();
        delete model.id;
        this.setState({ showLoader: true });
        const user = await db.collection(CONSTANT.collection.dbMeatcut).doc(`${meatcutId}`).get();
        if (!user.exists) return toast.error("Meatcut not found.");

        await db.collection(CONSTANT.collection.dbMeatcut).doc(`${meatcutId}`).set(model, { merge: true });
        this.setState({ showLoader: false });
        window.location.href = CONSTANT.url.meatcutList;
    }
}

export default EditMeatcut;