import React from 'react';

const ComponentName = (props: any) => {
    return (
        <div className="kt-subheader   kt-grid__item" id="kt_subheader">
            <div className="kt-container  kt-container--fluid ">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">
                        {props.headerTitle}
                    </h3>
                    {props.seperatorTitle &&
                        <React.Fragment>
                            <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                            <div className="kt-subheader__group" id="kt_subheader_search">
                                <span className="kt-subheader__desc font-weight-bold" id="kt_subheader_total">
                                    {props.seperatorTitle}
                                </span>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default ComponentName