const CONSTANT = {
    url: {
        login: '/',
        register: '/register',
        forgotPassword: '/forgot-password',
        changePassword: '/change-password',
        dashboard: '/dashboard',
        logout: '/logout',
        profile: '/profile',
        profileEdit: '/profile/:id',

        user: '/user',
        userDetail: '/user/detail/:id',

        pdfManagement: '/pdf-management',
        inventoryManagement: '/inventory-management',
        salesManagement: '/sales-management',

        meatcutList: '/database/meat-product',
        addMeatcut: '/database/meat-product/add',
        meatcutDetail: '/database/meat-product/detail/:id',

        muscleList: '/database/muscle',
        addMuscle: '/database/muscle/add',
        muscleDetail: '/database/muscle/detail/:id',

        definitionList: '/database/definition',
        addDefinition: '/database/definition/add',
        definitionDetail: '/database/definition/detail/:id',

        packagingList: '/database/packaging',
        addPackaging: '/database/packaging/add',
        packagingDetail: '/database/packaging/detail/:id',

        checklist: '/database/checklist',
        addChecklist: '/database/checklist/add',
        checklistDetail: '/database/checklist/detail/:id',

        carcaseList: '/cms-pages/carcase',
        addCarcase: '/cms-pages/carcase/add',
        editCarcase: '/cms-pages/carcase/detail/:id',

        aboutUs: '/cms-pages/about-us',
        addAboutUs: '/cms-pages/about-us/add',
        editAboutUs: '/cms-pages/about-us/detail/:id',

        faq: '/cms-pages/faq',
        addFaq: '/cms-pages/faq/add',
        editFaq: '/cms-pages/faq/detail/:id',

        termCondition: '/cms-pages/term-condition',
        categories: '/cms-pages/category',

        foodIntegrity: '/cms-pages/food-integrity',
        addFoodIntegrity: '/cms-pages/food-integrity/add',
        editFoodIntegrity: '/cms-pages/food-integrity/detail/:id',

        marketingResearch: '/cms-pages/marketing-research',
        addMarketingResearch: '/cms-pages/marketing-research/add',
        editMarketingResearch: '/cms-pages/marketing-research/detail/:id',

        threedModel: '/3d-model',

        notifications: '/notifications'
    },
    keys: {
        token: 'tk.ausmeat',
        userDetail: 'ud.ausmeat',
        remember: 'ld.ausmeat',

    },
    fileDirectory: {
        profilePicture: 'profile_picture',
        model3d: 'model3d',
        carcase: 'carcase',
        photoRef: 'photo_ref',
        productImage: 'product_image',
        pdf: 'pdf',
        inventory: 'inventory',
        animalImage: 'animal_image',
        texture: 'texture',
        skelton: 'skelton'
    },
    collection: {
        users: 'users',
        pdfManagement: 'view_handbook_pdf',
        inventoryManagement: 'inventory',
        myOrder: 'my_order',
        dbMeatcut: 'meatcut',
        dbMuscle: 'muscle',
        dbDefinition: 'definition',
        dbPackaging: 'packaging',
        dbMeatChecklist: 'meat_checklist',
        cmsCarcaseCategory: 'cms_carcase_category',
        cmsCarcaseSubCategory: 'cms_carcase_subcategory',
        cmsCarcase: 'cms_carcase',
        cmsAboutUs: 'cms_about_us',
        cmsTermCondition: 'cms_terms_condition',
        cmsCategoryAnimal: 'cms_category_animal',
        cmsCategoryLevel1: 'cms_category_level1',
        cmsCategoryLevel2: 'cms_category_level2',
        cmsCategoryLevel3: 'cms_category_level3',
        cmsCategoryMeat: 'cms_category_aus_meat',
        cmsCategoryMuscle: 'cms_category_muscle',
        cmsFaq: 'cms_faq',
        industryOverview: 'industry_overview',
        model3d: 'model_3d',
        model3dPoint: 'model_3d_Point',
    }
}
export default CONSTANT;