import * as React from 'react';
import Swal from 'sweetalert2/dist/sweetalert2'
import 'sweetalert2/src/sweetalert2.scss';
import firebase from "firebase/app";
import { ToastContainer } from 'react-toastify';

import { onChange, validateForm } from '../../utils';
import { db } from './../../firebase/index';
import CONSTANT from '../../constant';
import Loader from '../../component/common/Loader';
import Dropzone from './../common/Dropzone';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: detail ? detail.id : undefined,
            showLoader: false,
            title: { name: 'title', value: detail && detail.title ? detail.title : '', error: '', isRequired: true },
            country: { name: 'country', value: detail && detail.country ? detail.country : '', error: '', isRequired: true },
            code: { name: 'code', value: detail && detail.code ? detail.code : '', error: '', isRequired: true },
            pdfUrl: detail && detail.pdfUrl ? detail.pdfUrl : undefined,
            pdfFileName: detail && detail.pdfFileName ? detail.pdfFileName : undefined,
            fileSize: detail && detail.fileSize ? detail.fileSize : undefined,
        }
    }

    async componentDidMount() {
        const carcaseCategoryListArray: any[] = [];
        const carcaseCategorQuerySnapshot = await db.collection(CONSTANT.collection.cmsCarcaseCategory).get();
        carcaseCategorQuerySnapshot.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            carcaseCategoryListArray.push(data);
        });
        this.setState({
            category: { ...this.state.category, options: carcaseCategoryListArray },
        });
    }

    public render() {
        const { showLoader, title, code, pdfUrl, country } = this.state;
        return (
            <React.Fragment>
                <form onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label className="col col-form-label">PDF Title</label>
                                <div className="col-lg-12 form-group">
                                    <input
                                        type="text"
                                        className={title.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        placeholder="Enter title"
                                        name={title.name}
                                        value={title.value}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label className="col col-form-label">PDF Code</label>
                                <div className="col-lg-12 form-group">
                                    <input
                                        type="text"
                                        className={code.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        placeholder="Enter pdf code"
                                        name={code.name}
                                        value={code.value}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label className="col col-form-label">Country Available</label>
                                <div className="col-lg-12 form-group">
                                    <input
                                        type="text"
                                        className={country.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        placeholder="Enter country name"
                                        name={country.name}
                                        value={country.value}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            {pdfUrl ?
                                <div className="">
                                    <label className="col col-form-label">PDF File</label>
                                </div>
                                :
                                <div className="form-group">
                                    <label className="col col-form-label">PDF File</label>
                                    <Dropzone
                                        acceptFile={this.onFilesPdf}
                                        title={"Drag & Drop or Browse file or click to add file"}
                                    />
                                </div>
                            }
                            {pdfUrl &&
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    <strong>{pdfUrl}</strong>
                                    <button type="button" className="btn close" aria-label="Close" onClick={this.onDeletePdf}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                            }
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <button onClick={this.props.onCancel} type="button" className="btn btn-secondary btn-sm btn-block">Cancel</button>
                        </div>
                        <div className="col-md-3" />
                        <div className="col-md-3" />
                        <div className="col-md-3">
                            <button type="submit" className="btn btn-primary btn-sm btn-sm btn-block">Save Changes</button>
                        </div>
                    </div>
                </form>
                {
                    showLoader && <Loader />
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }


    onFilesPdf = (files: any) => {
        var file = files[0];
        this.handleProductImageUpload(file);
    }

    handleProductImageUpload = async (file: any) => {
        this.setState({ showLoader: true });

        const extArray = file.name.split('.');
        const ext = extArray[extArray.length - 1];
        const originalFilename = extArray[0];
        let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
        const fileDirectory = `${CONSTANT.fileDirectory.pdf}/${fileName}`;
        const size = file.size;

        const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

        uploadTask.on("state_changed", (snapshot: any) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.setState({ progress });
        },
            (error: any) => {
                console.log(error);
            },
            () => {
                firebase.app().storage()
                    .ref(CONSTANT.fileDirectory.pdf)
                    .child(fileName)
                    .getDownloadURL()
                    .then(url => {
                        this.setState({ pdfUrl: url, pdfFileName: fileName, fileSize: size, showLoader: false });
                    });
            }
        );
    };

    onDeletePdf = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete pdf?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result: any) => {
            if (result.value) {
                this.deletePdf();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your pdf image is safe :)',
                    'error'
                )
            }
        })
    }

    deletePdf = () => {
        this.setState({ showLoader: true });
        firebase.app().storage()
            .ref(CONSTANT.fileDirectory.pdf)
            .child(this.state.pdfFileName)
            .delete()
            .then(url => {
                this.setState({ pdfFileName: "", pdfUrl: "", showLoader: false });
                Swal.fire(
                    'Deleted!',
                    'Pdf image has been deleted.',
                    'success'
                )
            });
    }


    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const { title, code, pdfUrl, pdfFileName, country, fileSize } = this.state;

            let model: any = {
                title: title.value,
                titleFlag: title.value.toLowerCase(),
                pdfUrl: pdfUrl,
                code: code.value,
                pdfFileName: pdfFileName,
                country: country.value,
                fileSize: fileSize
            }
            if (this.state.id) {
                model.id = this.state.id;
            }
            this.props.onSubmit(model);
        }
    }

}

export default AddComponent;