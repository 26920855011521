import * as React from 'react';
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from '../../component/common/Footer';
import Loader from '../../component/common/Loader';
import SubHeader from '../../component/common/SubHeader';
import CONSTANT from '../../constant';
import moment from 'moment';

import ModalWindow from './../../component/common/ModalWindow';
import AnimalDetail from './../../component/cms-pages/AnimalDetail';
import LevellOneDetail from './../../component/cms-pages/LevelOneDetail';
import MetailDetailComponent from './../../component/cms-pages/MeatDetail';
import CarcaseCategoryComponent from './../../component/cms-pages/CarcaseCategory';
import CarcaseSubCategoryComponent from './../../component/cms-pages/CarcaseSubCategory';
// import StudDetail from './../../component/content-management/StudDetail';
// import CategoryDetail from './../../component/content-management/CategoryDetail';
// import AnimalBreedMap from './../../component/content-management/AnimalBreedMap';
import { db } from './../../firebase/index';

class Category extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false,
            showAnimalModal: false,
            showLevelOneModal: false,
            showLevelTwoModal: false,
            showLevelThreeModal: false,
            showMeatModal: false,
            showMuscleModal: false,
            showCarcaseCategoryModal: false,
            showCarcaseSubCategoryModal: false,

            animalList: undefined,
            levelOneList: undefined,
            levelTwoList: undefined,
            levelThreeList: undefined,
            meatList: undefined,
            muscleList: undefined,
            carcaseCategoryList: undefined,
            carcaseSubCategoryList: undefined,

            animalDetail: undefined,
            levelOneDetail: undefined,
            levelTwoDetail: undefined,
            levelThreeDetail: undefined,
            meatDetail: undefined,
            muscleDetail: undefined,
            carcaseCategoryDetail: undefined,
            carcaseSubCategoryDetail: undefined
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const {
            showLoader, showAnimalModal, showLevelOneModal, showLevelTwoModal, showLevelThreeModal, showMeatModal,
            showMuscleModal, animalDetail, levelTwoDetail, levelThreeDetail, meatDetail, muscleDetail, showCarcaseCategoryModal,
            showCarcaseSubCategoryModal, carcaseCategoryDetail, carcaseSubCategoryDetail
        } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                <SubHeader headerTitle="Category management" seperatorTitle="" />
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showAnimalModal && <ModalWindow
                        className="modal-lg"
                        title="Add New Animal type"
                        backdrop="static"
                        toggleModal={this.onCancel}>
                        <AnimalDetail
                            onSubmit={this.onSubmitAnimal}
                            onCancel={this.onCancel}
                            detail={animalDetail}
                        />
                    </ModalWindow>
                }
                {
                    showLevelOneModal && <ModalWindow
                        className="modal-lg"
                        title="Add New Level 1"
                        backdrop="static"
                        toggleModal={this.onCancel}>
                        <LevellOneDetail
                            onSubmit={this.onSubmitLevelOne}
                            onCancel={this.onCancel}
                            detail={levelTwoDetail}
                        />
                    </ModalWindow>
                }
                {
                    showLevelTwoModal && <ModalWindow
                        className="modal-lg"
                        title="Add New Level 2"
                        backdrop="static"
                        toggleModal={this.onCancel}>
                        <LevellOneDetail
                            onSubmit={this.onSubmitLevelTwo}
                            onCancel={this.onCancel}
                            detail={levelTwoDetail}
                        />
                    </ModalWindow>
                }
                {
                    showLevelThreeModal && <ModalWindow
                        className="modal-lg"
                        title="Add New Level 3"
                        backdrop="static"
                        toggleModal={this.onCancel}>
                        <LevellOneDetail
                            onSubmit={this.onSubmitLevelThree}
                            onCancel={this.onCancel}
                            detail={levelThreeDetail}
                        />
                    </ModalWindow>
                }
                {
                    showMeatModal && <ModalWindow
                        className="modal-lg"
                        title="Add New Meat Category"
                        backdrop="static"
                        toggleModal={this.onCancel}>
                        <MetailDetailComponent
                            onSubmit={this.onSubmitMeat}
                            onCancel={this.onCancel}
                            detail={meatDetail}
                        />
                    </ModalWindow>
                }
                {
                    showMuscleModal && <ModalWindow
                        className="modal-lg"
                        title="Add New Muscle Category"
                        backdrop="static"
                        toggleModal={this.onCancel}>
                        <MetailDetailComponent
                            onSubmit={this.onSubmitMuscle}
                            onCancel={this.onCancel}
                            detail={muscleDetail}
                        />
                    </ModalWindow>
                }
                {
                    showCarcaseCategoryModal && <ModalWindow
                        className="modal-lg"
                        title="Add New Carcase Category"
                        backdrop="static"
                        toggleModal={this.onCancel}>
                        <CarcaseCategoryComponent
                            onSubmit={this.onSubmitCarcaseCategory}
                            onCancel={this.onCancel}
                            detail={carcaseCategoryDetail}
                        />
                    </ModalWindow>
                }
                {
                    showCarcaseSubCategoryModal && <ModalWindow
                        className="modal-lg"
                        title="Add New Carcase Sub Category"
                        backdrop="static"
                        toggleModal={this.onCancel}>
                        <CarcaseSubCategoryComponent
                            onSubmit={this.onSubmitCarcaseSubCategory}
                            onCancel={this.onCancel}
                            detail={carcaseSubCategoryDetail}
                        />
                    </ModalWindow>
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Category management
                        </h3>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { animalList, levelOneList, levelTwoList, levelThreeList, meatList, muscleList, carcaseCategoryList, carcaseSubCategoryList } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-md-4">
                        <div className="kt-portlet">

                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Animal
                                    </h3>
                                </div>
                            </div>

                            <div className="kt-portlet__body">
                                <div className="row">
                                    <table className="table table-bordered table-hover">
                                        <thead className="bg-light">
                                            <tr>
                                                <th>Name</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                animalList !== undefined && animalList.map((item: any, index: number) => {
                                                    return (
                                                        <React.Fragment>
                                                            <tr key={index}>
                                                                <td>
                                                                    <div className="d-flex flex-wrap align-items-center">
                                                                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                                                            <span className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg">{item.name}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <button className="btn btn-sm btn-danger pull-right ml-3" onClick={() => this.onDeleteAnimal(item)}><i className="fa fa-trash-alt"></i></button>
                                                                    {/* <button className="btn btn-sm btn-primary pull-right" onClick={() => this.onEditAnimalType(item)}><i className="fa fa-pencil-alt"></i></button> */}
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                        <button type="button" className="btn btn-sm btn-primary pull-right" onClick={this.onAddAnimal}>Add new animal</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="kt-portlet">

                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Level 1
                                    </h3>
                                </div>
                            </div>

                            <div className="kt-portlet__body">
                                <div className="row">
                                    <table className="table table-bordered table-hover">
                                        <thead className="bg-light">
                                            <tr>
                                                <th>Name</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                levelOneList !== undefined && levelOneList.map((item: any, index: number) => {
                                                    return (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>
                                                                    <div className="d-flex flex-wrap align-items-center">
                                                                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                                                            <span className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg">{item.name}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <button className="btn btn-sm btn-danger pull-right ml-3" onClick={() => this.onDeleteLevelOne(item)}><i className="fa fa-trash-alt"></i></button>
                                                                    {/* <button className="btn btn-sm btn-primary pull-right" onClick={() => this.onEditAnimalType(item)}><i className="fa fa-pencil-alt"></i></button> */}
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                        <button type="button" className="btn btn-sm btn-primary pull-right" onClick={this.onAddLevelOne}>Add new</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="kt-portlet">

                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Level 2
                                    </h3>
                                </div>
                            </div>

                            <div className="kt-portlet__body">
                                <div className="row">
                                    <table className="table table-bordered table-hover">
                                        <thead className="bg-light">
                                            <tr>
                                                <th>Name</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                levelTwoList !== undefined && levelTwoList.map((item: any, index: number) => {
                                                    return (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>
                                                                    <div className="d-flex flex-wrap align-items-center">
                                                                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                                                            <span className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg">{item.name}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <button className="btn btn-sm btn-danger pull-right ml-3" onClick={() => this.onDeleteLevelTwo(item)}><i className="fa fa-trash-alt"></i></button>
                                                                    {/* <button className="btn btn-sm btn-primary pull-right" onClick={() => this.onEditAnimalType(item)}><i className="fa fa-pencil-alt"></i></button> */}
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                        <button type="button" className="btn btn-sm btn-primary pull-right" onClick={this.onAddLevelTwo}>Add new</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-3 d-none">
                        <div className="kt-portlet">

                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Level 3
                                    </h3>
                                </div>
                            </div>

                            <div className="kt-portlet__body">
                                <div className="row">
                                    <table className="table table-bordered table-hover">
                                        <thead className="bg-light">
                                            <tr>
                                                <th>Name</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                levelThreeList !== undefined && levelThreeList.map((item: any, index: number) => {
                                                    return (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>
                                                                    <div className="d-flex flex-wrap align-items-center">
                                                                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                                                            <span className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg">{item.name}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <button className="btn btn-sm btn-danger pull-right ml-3" onClick={() => this.onDeleteLevelThree(item)}><i className="fa fa-trash-alt"></i></button>
                                                                    {/* <button className="btn btn-sm btn-primary pull-right" onClick={() => this.onEditAnimalType(item)}><i className="fa fa-pencil-alt"></i></button> */}
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                        <button type="button" className="btn btn-sm btn-primary pull-right" onClick={this.onAddLevelThree}>Add new</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Aus Meat
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="row">
                                    <table className="table table-bordered table-hover">
                                        <thead className="bg-light">
                                            <tr>
                                                <th>Aus Meat</th>
                                                <th>Code</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                meatList !== undefined && meatList.map((item: any, index: number) => {
                                                    return (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>{item.name}</td>
                                                                <td>
                                                                    <div className="d-flex flex-wrap align-items-center">
                                                                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                                                            <span className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg">{item.code}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <button className="btn btn-sm btn-danger pull-right ml-3" onClick={() => this.onDeleteMeat(item)}><i className="fa fa-trash-alt"></i></button>
                                                                    {/* <button className="btn btn-sm btn-primary pull-right" onClick={() => this.onEditBreed(item)}><i className="fa fa-pencil-alt"></i></button> */}
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <button type="button" className="btn btn-sm btn-primary pull-right" onClick={this.onAddMeat}>Add new</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Muscle Name
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="row">
                                    <table className="table table-bordered table-hover">
                                        <thead className="bg-light">
                                            <tr>
                                                <th>Muscle name</th>
                                                <th>Code</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                muscleList !== undefined && muscleList.map((item: any, index: number) => {
                                                    return (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>{item.name}</td>
                                                                <td>
                                                                    <div className="d-flex flex-wrap align-items-center">
                                                                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                                                            <span className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg">{item.code}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <button className="btn btn-sm btn-danger pull-right ml-3" onClick={() => this.onDeleteMuscle(item)}><i className="fa fa-trash-alt"></i></button>
                                                                    {/* <button className="btn btn-sm btn-primary pull-right" onClick={() => this.onEditStudName(item)}><i className="fa fa-pencil-alt"></i></button> */}
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <button type="button" className="btn btn-sm btn-primary pull-right" onClick={this.onAddMuscle}>Add new</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Carcase Category
                                    </h3>
                                </div>
                            </div>
                            <div className="kt-portlet__body">
                                <div className="row">
                                    <table className="table table-bordered table-hover">
                                        <thead className="bg-light">
                                            <tr>
                                                <th>Name</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                carcaseCategoryList !== undefined && carcaseCategoryList.map((item: any, index: number) => {
                                                    return (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>
                                                                    <div className="d-flex flex-wrap align-items-center">
                                                                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                                                            <span className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg">{item.name}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <button className="btn btn-sm btn-danger pull-right ml-3" onClick={() => this.onDeleteCarcaseCategory(item)}><i className="fa fa-trash-alt"></i></button>
                                                                    {/* <button className="btn btn-sm btn-primary pull-right" onClick={() => this.onEditAnimalType(item)}><i className="fa fa-pencil-alt"></i></button> */}
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                        <button type="button" className="btn btn-sm btn-primary pull-right" onClick={this.onAddCarcaseCategory}>Add new</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="kt-portlet">

                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">
                                        Carcase Sub Category
                                    </h3>
                                </div>
                            </div>

                            <div className="kt-portlet__body">
                                <div className="row">
                                    <table className="table table-bordered table-hover">
                                        <thead className="bg-light">
                                            <tr>
                                                <th>Name</th>
                                                <th>Category</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                carcaseSubCategoryList !== undefined && carcaseSubCategoryList.map((item: any, index: number) => {
                                                    return (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>
                                                                    <div className="d-flex flex-wrap align-items-center">
                                                                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                                                            <span className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg">{item.name}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex flex-wrap align-items-center">
                                                                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                                                            <span className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg">{item.category}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <button className="btn btn-sm btn-danger pull-right ml-3" onClick={() => this.onDeleteCarcaseSubCategory(item)}><i className="fa fa-trash-alt"></i></button>
                                                                    {/* <button className="btn btn-sm btn-primary pull-right" onClick={() => this.onEditAnimalType(item)}><i className="fa fa-pencil-alt"></i></button> */}
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                        <button type="button" className="btn btn-sm btn-primary pull-right" onClick={this.onAddCarcaseSubCategory}>Add new</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    loadList = async () => {
        this.setState({ showLoader: true });
        const animalListArray: any[] = [];
        const levelOneListArray: any[] = [];
        const levelTwoListArray: any[] = [];
        const levelThreeListArray: any[] = [];
        const meatListArray: any[] = [];
        const muscleListArray: any[] = [];
        const carcaseCategoryListArray: any[] = [];
        const carcaseSubCategoryListArray: any[] = [];

        const animalQuerySnapshot = await db.collection(CONSTANT.collection.cmsCategoryAnimal).where("isActive", "==", true).orderBy('modifiedOn', 'asc').get();
        animalQuerySnapshot.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            animalListArray.push(data);
        });

        const levelOneQuerySnapshot = await db.collection(CONSTANT.collection.cmsCategoryLevel1).where("isActive", "==", true).orderBy('modifiedOn', 'asc').get();
        levelOneQuerySnapshot.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            levelOneListArray.push(data);
        });

        const levelTwoQuerySnapshot = await db.collection(CONSTANT.collection.cmsCategoryLevel2).where("isActive", "==", true).orderBy('modifiedOn', 'asc').get();
        levelTwoQuerySnapshot.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            levelTwoListArray.push(data);
        });

        const levelThreeQuerySnapshot = await db.collection(CONSTANT.collection.cmsCategoryLevel3).where("isActive", "==", true).orderBy('modifiedOn', 'asc').get();
        levelThreeQuerySnapshot.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            levelThreeListArray.push(data);
        });

        const meatQuerySnapshot = await db.collection(CONSTANT.collection.cmsCategoryMeat).where("isActive", "==", true).orderBy('modifiedOn', 'asc').get();
        meatQuerySnapshot.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            meatListArray.push(data);
        });

        const muscleQuerySnapshot = await db.collection(CONSTANT.collection.cmsCategoryMuscle).where("isActive", "==", true).orderBy('modifiedOn', 'asc').get();
        muscleQuerySnapshot.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            muscleListArray.push(data);
        });

        const carcaseCategorQuerySnapshot = await db.collection(CONSTANT.collection.cmsCarcaseCategory).where("isActive", "==", true).orderBy('modifiedOn', 'asc').get();
        carcaseCategorQuerySnapshot.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            carcaseCategoryListArray.push(data);
        });

        const carcaseSubCategorQuerySnapshot = await db.collection(CONSTANT.collection.cmsCarcaseSubCategory).where("isActive", "==", true).orderBy('modifiedOn', 'asc').get();
        carcaseSubCategorQuerySnapshot.forEach(async (doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            carcaseSubCategoryListArray.push(data);
        });


        const newSub = await Promise.all(carcaseSubCategoryListArray.map(async (item: any) => {
            let itemObj = { ...item };
            const getData = await db.collection(CONSTANT.collection.cmsCarcaseCategory).doc(`${item.categoryId}`).get();
            let userData = getData.data();
            itemObj.category = userData.name;
            return itemObj;
        }));

        this.setState({
            showLoader: false,
            animalList: animalListArray,
            levelOneList: levelOneListArray,
            levelTwoList: levelTwoListArray,
            levelThreeList: levelThreeListArray,
            meatList: meatListArray,
            muscleList: muscleListArray,
            carcaseCategoryList: carcaseCategoryListArray,
            carcaseSubCategoryList: newSub,
        });
    }

    onAddAnimal = () => {
        this.setState({ showAnimalModal: true });
    }

    onAddLevelOne = () => {
        this.setState({ showLevelOneModal: true });
    }

    onAddLevelTwo = () => {
        this.setState({ showLevelTwoModal: true });
    }

    onAddLevelThree = () => {
        this.setState({ showLevelThreeModal: true });
    }

    onAddMeat = () => {
        this.setState({ showMeatModal: true });
    }

    onAddMuscle = () => {
        this.setState({ showMuscleModal: true });
    }

    onAddCarcaseCategory = () => {
        this.setState({ showCarcaseCategoryModal: true });
    }

    onAddCarcaseSubCategory = () => {
        this.setState({ showCarcaseSubCategoryModal: true });
    }

    onCancel = () => {
        this.setState({
            showAnimalModal: false, showLevelOneModal: false, showLevelTwoModal: false, showLevelThreeModal: false,
            showMeatModal: false, showMuscleModal: false, showCarcaseCategoryModal: false, showCarcaseSubCategoryModal: false,
        });
    }

    onEditAnimal = (item: any) => {
        this.setState({ animalDetail: item, showAnimalModal: true });
    }

    onDeleteAnimal = (item: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete ${item.name}'?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then(async (result: any) => {
            if (result.value) {
                this.setState({ showLoader: true });
                await db.collection(CONSTANT.collection.cmsCategoryAnimal).doc(`${item.id}`).delete();
                toast.success('Success');
                this.loadList();
                this.setState({ showLoader: false });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    '',
                    'error'
                )
            }
        });
    }

    onDeleteLevelOne = (item: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete ${item.name}'?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then(async (result: any) => {
            if (result.value) {
                this.setState({ showLoader: true });
                await db.collection(CONSTANT.collection.cmsCategoryLevel1).doc(`${item.id}`).delete();
                toast.success('Success');
                this.loadList();
                this.setState({ showLoader: false });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    '',
                    'error'
                )
            }
        });
    }

    onDeleteLevelTwo = (item: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete ${item.name}'?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then(async (result: any) => {
            if (result.value) {
                this.setState({ showLoader: true });
                await db.collection(CONSTANT.collection.cmsCategoryLevel2).doc(`${item.id}`).delete();
                toast.success('Success');
                this.loadList();
                this.setState({ showLoader: false });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    '',
                    'error'
                )
            }
        });
    }

    onDeleteLevelThree = (item: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete ${item.name}'?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then(async (result: any) => {
            if (result.value) {
                this.setState({ showLoader: true });
                await db.collection(CONSTANT.collection.cmsCategoryLevel3).doc(`${item.id}`).delete();
                toast.success('Success');
                this.loadList();
                this.setState({ showLoader: false });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    '',
                    'error'
                )
            }
        });
    }

    onDeleteMeat = (item: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete ${item.name}'?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then(async (result: any) => {
            if (result.value) {
                this.setState({ showLoader: true });
                await db.collection(CONSTANT.collection.cmsCategoryMeat).doc(`${item.id}`).delete();
                toast.success('Success');
                this.loadList();
                this.setState({ showLoader: false });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    '',
                    'error'
                )
            }
        });
    }

    onDeleteMuscle = (item: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete ${item.name}'?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then(async (result: any) => {
            if (result.value) {
                this.setState({ showLoader: true });
                await db.collection(CONSTANT.collection.cmsCategoryMuscle).doc(`${item.id}`).delete();
                toast.success('Success');
                this.loadList();
                this.setState({ showLoader: false });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    '',
                    'error'
                )
            }
        });
    }

    onSubmitAnimal = async (reqObj: any) => {
        reqObj.modifiedOn = moment().unix();
        reqObj.isActive = true;
        this.onCancel();
        this.setState({ showLoader: true });
        let arrTemp: any = [];
        let animalData: any = await db.collection(CONSTANT.collection.cmsCategoryAnimal).where("nameFlag", "==", reqObj.nameFlag).where("isActive", "==", true).get();
        animalData.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            arrTemp.push(data);
        });
        if (reqObj.id) {
            
        } else {
            if (arrTemp.length === 0) {
                await db.collection(CONSTANT.collection.cmsCategoryAnimal).add(reqObj);
            } else {
                this.setState({ showLoader: false });
                toast.error('Animal name already exist');
                return;
            }
        }
        this.setState({ showLoader: false }, () => this.loadList());
    }

    onSubmitLevelOne = async (reqObj: any) => {
        reqObj.modifiedOn = moment().unix();
        reqObj.isActive = true;
        this.onCancel();
        this.setState({ showLoader: true });
        let arrTemp: any = [];
        let animalData: any = await db.collection(CONSTANT.collection.cmsCategoryLevel1).where("nameFlag", "==", reqObj.name.toLowerCase()).get();
        animalData.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            arrTemp.push(data);
        });
        if (reqObj.id) {
            
        } else {
            if (arrTemp.length === 0) {
                await db.collection(CONSTANT.collection.cmsCategoryLevel1).add(reqObj);
            } else {
                this.setState({ showLoader: false });
                toast.error('Level 1 already exist');
                return;
            }
        }
        this.setState({ showLoader: false }, () => this.loadList());
    }

    onSubmitLevelTwo = async (reqObj: any) => {
        reqObj.modifiedOn = moment().unix();
        reqObj.isActive = true;
        this.onCancel();
        this.setState({ showLoader: true });
        let arrTemp: any = [];
        let animalData: any = await db.collection(CONSTANT.collection.cmsCategoryLevel2).where("nameFlag", "==", reqObj.name.toLowerCase()).get();
        animalData.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            arrTemp.push(data);
        });
        if (reqObj.id) {
            
        } else {
            if (arrTemp.length === 0) {
                await db.collection(CONSTANT.collection.cmsCategoryLevel2).add(reqObj);
            } else {
                this.setState({ showLoader: false });
                toast.error('Level 2 already exist');
                return;
            }
        }
        this.setState({ showLoader: false }, () => this.loadList());
    }

    onSubmitLevelThree = async (reqObj: any) => {
        reqObj.modifiedOn = moment().unix();
        reqObj.isActive = true;
        this.onCancel();
        this.setState({ showLoader: true });
        let arrTemp: any = [];
        let animalData: any = await db.collection(CONSTANT.collection.cmsCategoryLevel3).where("nameFlag", "==", reqObj.name.toLowerCase()).get();
        animalData.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            arrTemp.push(data);
        });
        if (reqObj.id) {
            
        } else {
            if (arrTemp.length === 0) {
                await db.collection(CONSTANT.collection.cmsCategoryLevel3).add(reqObj);
            } else {
                this.setState({ showLoader: false });
                toast.error('Level 3 already exist');
                return;
            }
        }
        this.setState({ showLoader: false }, () => this.loadList());
    }

    onSubmitMeat = async (reqObj: any) => {
        reqObj.modifiedOn = moment().unix();
        reqObj.isActive = true;
        this.onCancel();
        this.setState({ showLoader: true });
        let arrTempName: any = [];
        let arrTempCode: any = [];
        let meatNameData: any = await db.collection(CONSTANT.collection.cmsCategoryMeat).where("nameFlag", "==", reqObj.name.toLowerCase()).get();
        meatNameData.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            arrTempName.push(data);
        });
        let meatCodeData: any = await db.collection(CONSTANT.collection.cmsCategoryMeat).where("code", "==", reqObj.code).get();
        meatCodeData.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            arrTempCode.push(data);
        });
        if (reqObj.id) {
            
        } else {
            if (arrTempName.length > 0) {
                this.setState({ showLoader: false });
                toast.error('Meat name already exist');
                return;
            } else if (arrTempCode.length > 0) {
                this.setState({ showLoader: false });
                toast.error('Meat code already exist');
                return;
            } else {
                await db.collection(CONSTANT.collection.cmsCategoryMeat).add(reqObj);
            }
        }
        this.setState({ showLoader: false }, () => this.loadList());
    }

    onSubmitMuscle = async (reqObj: any) => {
        reqObj.modifiedOn = moment().unix();
        reqObj.isActive = true;
        this.onCancel();
        this.setState({ showLoader: true });
        let arrTempName: any = [];
        let arrTempCode: any = [];
        let meatNameData: any = await db.collection(CONSTANT.collection.cmsCategoryMuscle).where("nameFlag", "==", reqObj.name.toLowerCase()).get();
        meatNameData.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            arrTempName.push(data);
        });
        let meatCodeData: any = await db.collection(CONSTANT.collection.cmsCategoryMuscle).where("code", "==", reqObj.code).get();
        meatCodeData.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            arrTempCode.push(data);
        });
        if (reqObj.id) {
            
        } else {
            if (arrTempName.length > 0) {
                this.setState({ showLoader: false });
                toast.error('Meat name already exist');
                return;
            } else if (arrTempCode.length > 0) {
                this.setState({ showLoader: false });
                toast.error('Meat code already exist');
                return;
            } else {
                await db.collection(CONSTANT.collection.cmsCategoryMuscle).add(reqObj);
            }
        }
        this.setState({ showLoader: false }, () => this.loadList());
    }

    onSubmitCarcaseCategory = async (reqObj: any) => {
        reqObj.modifiedOn = moment().unix();
        reqObj.isActive = true;
        this.onCancel();
        this.setState({ showLoader: true });
        let arrTemp: any = [];
        let animalData: any = await db.collection(CONSTANT.collection.cmsCarcaseCategory).where("nameFlag", "==", reqObj.name.toLowerCase()).get();
        animalData.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            arrTemp.push(data);
        });
        if (reqObj.id) {
            
        } else {
            if (arrTemp.length === 0) {
                await db.collection(CONSTANT.collection.cmsCarcaseCategory).add(reqObj);
            } else {
                this.setState({ showLoader: false });
                toast.error('Level 3 already exist');
                return;
            }
        }
        this.setState({ showLoader: false }, () => this.loadList());
    }

    onDeleteCarcaseCategory = (item: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete ${item.name}'?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then(async (result: any) => {
            if (result.value) {
                this.setState({ showLoader: true });
                await db.collection(CONSTANT.collection.cmsCarcaseCategory).doc(`${item.id}`).delete();
                toast.success('Success');
                this.loadList();
                this.setState({ showLoader: false });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    '',
                    'error'
                )
            }
        });
    }

    onSubmitCarcaseSubCategory = async (reqObj: any) => {
        reqObj.modifiedOn = moment().unix();
        this.onCancel();
        this.setState({ showLoader: true });
        let arrTemp: any = [];
        let animalData: any = await db.collection(CONSTANT.collection.cmsCarcaseSubCategory).where("nameFlag", "==", reqObj.name.toLowerCase()).get();
        animalData.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            arrTemp.push(data);
        });
        if (reqObj.id) {
            
        } else {
            if (arrTemp.length === 0) {
                await db.collection(CONSTANT.collection.cmsCarcaseSubCategory).add(reqObj);
            } else {
                this.setState({ showLoader: false });
                toast.error('Level 3 already exist');
                return;
            }
        }
        this.setState({ showLoader: false }, () => this.loadList());
    }

    onDeleteCarcaseSubCategory = (item: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete ${item.name}'?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then(async (result: any) => {
            if (result.value) {
                this.setState({ showLoader: true });
                await db.collection(CONSTANT.collection.cmsCarcaseSubCategory).doc(`${item.id}`).delete();
                toast.success('Success');
                this.loadList();
                this.setState({ showLoader: false });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    '',
                    'error'
                )
            }
        });
    }

}

export default Category;