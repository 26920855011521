import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import Loader from './../../component/common/Loader';
import CONSTANT from './../../constant';
import { db } from './../../firebase/index';

class SaleList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            list: undefined,
            page: 1,
            limit: 10,
            sort: 'desc',
            count: 0,
            showLoader: false,
            keyword: { name: 'keyword', value: '', error: '', isRequired: false },
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Sales Management
                        </h3>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { list } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">

                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__body">

                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        {list !== undefined && this.renderTable()}
                                    </div>
                                </div>

                                {/* <div className="h-100 row align-space-between">
                                    <div className="col-12 d-flex justify-content-end mt-4">
                                        {count > limit &&
                                            <PaginationComponent
                                                classname="float-right"
                                                totalItems={count}
                                                pageSize={pageSize}
                                                onSelect={this.handleSelected}
                                            />
                                        }
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    renderTable = () => {
        const { list } = this.state;
        const columns = [{
            dataField: 'index',
            text: 'No.',
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return <span>{rowIndex + 1}</span>
            },
        }, {
            dataField: 'title',
            text: 'Item',
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return <span>{row.title}</span>
            },
        }, {
            dataField: 'price',
            text: 'Price',
            sort: true,
        }, {
            dataField: 'quantity',
            text: 'Qty. Sold',
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return <span>{`${row.quantity ? row.quantity : ''}`}</span>
            },
            sort: true
        }, {
            dataField: 'user',
            text: 'User',
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return <span>{row.displayName ? row.displayName : ''}</span>
            },
            sort: true
        }, {
            dataField: 'email',
            text: 'Email',
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return <span>{`${row.email}`}</span>
            },
            sort: true
        }];
        return (<React.Fragment>
            <BootstrapTable defaultSortDirection="asc" bootstrap4 keyField='index' data={list} columns={columns} />
        </React.Fragment>)
    }

    onChange = (e: any) => {
        const value = e.target.value;

        this.setState({ limit: Number(value) }, () => {
            this.loadList();
        });
    }

    onAddNew = () => {
        this.setState({ showModal: true });
    }

    loadList = async () => {
        try {
            this.setState({ showLoader: true });
            let listData: any[] = [];

            const countSnap: any = await db.collection(CONSTANT.collection.myOrder).get();

            const userQuerySnapshot = await db.collection(CONSTANT.collection.myOrder).get();
            userQuerySnapshot.forEach((doc: any) => {
                let data = doc.data();
                data.id = doc.id;
                listData.push(data);
            });


            const newListData = await Promise.all(listData.map(async (item: any) => {
                let itemObj = { ...item };
                itemObj.displayName = "";
                const getData = await db.collection(CONSTANT.collection.users).doc(`${item.uid}`).get();
                let userData = getData.data();
                if (userData) {
                    itemObj.displayName = userData.displayName ? userData.displayName : '';
                }
                return itemObj;
            }));

            this.setState({ list: newListData, count: countSnap.size, showLoader: false });
        } catch (err) {
            console.log(err);
        }
    }
}

export default SaleList;