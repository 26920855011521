import * as React from 'react';

import Header from '../component/common/Header';
import SubHeader from './../component/common/SubHeader';
import Sidebar from '../component/common/Sidebar';
import Footer from './../component/common/Footer';
import CONSTANT from './../constant';
import { db } from './../firebase/index';

class Dashboard extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false,
            userCount: undefined,
            meatcutCount: undefined,
            primalMeatcutCount: undefined,
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                <SubHeader headerTitle="Dashboard" seperatorTitle="" />
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderBody = () => {
        const { userCount, meatcutCount, primalMeatcutCount } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                <div className="row">
                    <div className="col-md-12">
                        <div className="row text-center">
                            <div className="col-md-8">
                                <div className="kt-portlet kt-portlet--height-fluid">

                                    <div className="kt-portlet__body kt-portlet__body--fit">

                                        <div className="kt-widget kt-widget--project-1 ">

                                            <div className="kt-widget__body text-center">

                                                <div className="kt-widget__stats text-center">

                                                    <div className="kt-widget__item col-lg-12 text-center mb-4">
                                                        <div className="kt-widget__label mt-4">
                                                            <h1 className="kt-font-bold">{userCount ? userCount : '-'}</h1>
                                                        </div>
                                                        <div className="kt-widget__label mt-4">
                                                            <h5 className="">Total Users</h5>
                                                        </div>
                                                        {/* <div className="kt-widget__label mt-4">
                                                            <a href={CONSTANT.url.vendor} className="kt-widget__value kt-font-brand">Vendor Management</a>
                                                        </div> */}
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4" />

                            {/* <div className="col-md-6 text-center">
                                <div className="kt-portlet kt-portlet--height-fluid">

                                    <div className="kt-portlet__body kt-portlet__body--fit">

                                        <div className="kt-widget kt-widget--project-1 ">

                                            <div className="kt-widget__body ">

                                                <div className="kt-widget__stats text-center">

                                                    <div className="kt-widget__item col-lg-12">
                                                        <div className="kt-widget__label mt-4">
                                                            <h1 className="kt-font-bold">{data ? data.userCount : '-'}</h1>
                                                        </div>
                                                        <div className="kt-widget__label mt-4">
                                                            <h5 className="">Overall App Rating</h5>
                                                        </div>
                                                        
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                </div>

                <div className="row">

                    <div className="col-md-8">

                        <div className="row text-center">
                            <div className="col-md-6">
                                <div className="kt-portlet kt-portlet--height-fluid">

                                    <div className="kt-portlet__body kt-portlet__body--fit">

                                        <div className="kt-widget kt-widget--project-1 ">

                                            <div className="kt-widget__body text-center">

                                                <div className="kt-widget__stats pt-4">

                                                    <div className="kt-widget__item col-lg-12 ">
                                                        <div className="kt-widget__label">
                                                            <h1 className="kt-font-bold">{meatcutCount ? meatcutCount : '-'}</h1>
                                                        </div>
                                                        <div className="kt-widget__label mt-4">
                                                            <h5 className="">Total No. of Meatcuts</h5>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="kt-portlet kt-portlet--height-fluid">

                                    <div className="kt-portlet__body kt-portlet__body--fit">

                                        <div className="kt-widget kt-widget--project-1 ">

                                            <div className="kt-widget__body text-center">

                                                <div className="kt-widget__stats pt-4">

                                                    <div className="kt-widget__item col-lg-12 ">
                                                        <div className="kt-widget__label">
                                                            <h1 className="kt-font-bold">{primalMeatcutCount ? primalMeatcutCount : '-'}</h1>
                                                        </div>
                                                        <div className="kt-widget__label mt-4">
                                                            <h5 className="">Total no. of Primal meatcuts</h5>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4" />
                </div>
            </div>
        )
    }

    loadList = async () => {
        try {
            this.setState({ showLoader: true });

            const userSnap: any = await db.collection(CONSTANT.collection.users).where("isActive", "==", true).where("role", "==", "user").get();

            const meatcutSnap: any = await db.collection(CONSTANT.collection.dbMeatcut).where('isActive', '==', true).get();

            this.setState({ userCount: userSnap.size, meatcutCount: meatcutSnap.size, primalMeatcutCount: 0, showLoader: false });
        } catch (err) {
            console.log(err);
        }
    }
}

export default Dashboard;