import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Loader from '../../component/common/Loader';
import { onChange, validateForm, getStorage } from './../../utils';
import CONSTANT from '../../constant';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: props.id,
            detail,
            currentUserId: undefined,
            showLoader: false,
            title: { name: 'title', value: detail && detail.title ? detail.title : '', error: '', isRequired: true, options: [] },
            text: { name: 'text', value: detail && detail.text ? detail.text : '', error: '', isRequired: true },
        }
    }

    componentDidMount() {
        const ud = getStorage(CONSTANT.keys.userDetail);
        this.setState({ currentUserId: ud.uid });
    }

    public render() {
        const { showLoader, title, text } = this.state;
        return (
            <React.Fragment>
                <form onSubmit={this.onSubmit}>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label className="col col-form-label">Title</label>
                                <div className="col-lg-12 form-group">
                                    <input
                                        type="text"
                                        className={title.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        placeholder="Enter title"
                                        name={title.name}
                                        value={title.value}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="col col-form-label">Text</label>
                                <div className="col-lg-12 form-group">
                                    <textarea
                                        className={text.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        rows={6}
                                        placeholder="Write text here"
                                        name={text.name}
                                        value={text.value}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-10" />
                        <div className="col-lg-2 mr-0">
                            <button type="submit" className="btn btn-primary btn-sm btn-block">Save</button>
                        </div>
                    </div>

                </form>
                {
                    showLoader && <Loader />
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;

        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const { title, text } = this.state;

            const model: any = {
                title: title.value,
                text: text.value
            };
            if (this.state.id) {
                model.id = this.state.id;
            }
            this.props.onSubmit(model);
        }

    }

}

export default AddComponent;