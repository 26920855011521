import React from 'react';

import Logo from './../../assets/images/logo_with_grey.png';
import CONSTANT from '../../constant';

const Sidebar = () => {
    return (
        <div className="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">
            <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand" kt-hidden-height="65" >
                <div className="kt-aside__brand-logo">
                    <a href={CONSTANT.url.dashboard}>
                        <img alt="Logo" src={Logo} style={{ height: 30 }} />
                    </a>
                </div>
                <div className="kt-aside__brand-tools">
                    <button className="kt-aside__brand-aside-toggler" id="kt_aside_toggler">
                        <span><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z" fill="#000000" fill-rule="nonzero" transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) " />
                                <path d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) " />
                            </g>
                        </svg></span>
                        <span><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero" />
                                <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) " />
                            </g>
                        </svg></span>
                    </button>
                </div>
            </div>
            <div className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
                <div id="kt_aside_menu" className="kt-aside-menu kt-scroll ps ps--active-y" data-ktmenu-vertical="1" data-ktmenu-scroll="1" data-ktmenu-dropdown-timeout="500">
                    <ul className="kt-menu__nav ">
                        <li className="kt-menu__item" aria-haspopup="true" role="menuitem">
                            <a href={CONSTANT.url.dashboard} className="kt-menu__link ">
                                <span className="kt-menu__link-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24"></rect>
                                            <rect fill="#000000" opacity="0.3" x="4" y="4" width="8" height="16"></rect>
                                            <path d="M6,18 L9,18 C9.66666667,18.1143819 10,18.4477153 10,19 C10,19.5522847 9.66666667,19.8856181 9,20 L4,20 L4,15 C4,14.3333333 4.33333333,14 5,14 C5.66666667,14 6,14.3333333 6,15 L6,18 Z M18,18 L18,15 C18.1143819,14.3333333 18.4477153,14 19,14 C19.5522847,14 19.8856181,14.3333333 20,15 L20,20 L15,20 C14.3333333,20 14,19.6666667 14,19 C14,18.3333333 14.3333333,18 15,18 L18,18 Z M18,6 L15,6 C14.3333333,5.88561808 14,5.55228475 14,5 C14,4.44771525 14.3333333,4.11438192 15,4 L20,4 L20,9 C20,9.66666667 19.6666667,10 19,10 C18.3333333,10 18,9.66666667 18,9 L18,6 Z M6,6 L6,9 C5.88561808,9.66666667 5.55228475,10 5,10 C4.44771525,10 4.11438192,9.66666667 4,9 L4,4 L9,4 C9.66666667,4 10,4.33333333 10,5 C10,5.66666667 9.66666667,6 9,6 L6,6 Z" fill="#000000" fill-rule="nonzero"></path>
                                        </g>
                                    </svg>
                                </span>
                                <span className="kt-menu__link-text">Dashboard</span></a>
                        </li>

                        {
                            renderUserManagementOptions()
                        }

                        {
                            renderDatabseManagementOptions()
                        }

                        {
                            renderPDFManagementOptions()
                        }

                        {
                            renderInventoryManagementOptions()
                        }

                        {
                            renderSalesManagementOptions()
                        }

                        {
                            renderModelManagementOptions()
                        }

                        {
                            renderCMSPagesOptions()
                        }

                        {/* <li className="kt-menu__item" aria-haspopup="true">
                            <a href={CONSTANT.url.logout} className="kt-menu__link ">
                                <span className="kt-menu__link-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24" />
                                            <path d="M14.0069431,7.00607258 C13.4546584,7.00607258 13.0069431,6.55855153 13.0069431,6.00650634 C13.0069431,5.45446114 13.4546584,5.00694009 14.0069431,5.00694009 L15.0069431,5.00694009 C17.2160821,5.00694009 19.0069431,6.7970243 19.0069431,9.00520507 L19.0069431,15.001735 C19.0069431,17.2099158 17.2160821,19 15.0069431,19 L3.00694311,19 C0.797804106,19 -0.993056895,17.2099158 -0.993056895,15.001735 L-0.993056895,8.99826498 C-0.993056895,6.7900842 0.797804106,5 3.00694311,5 L4.00694793,5 C4.55923268,5 5.00694793,5.44752105 5.00694793,5.99956624 C5.00694793,6.55161144 4.55923268,6.99913249 4.00694793,6.99913249 L3.00694311,6.99913249 C1.90237361,6.99913249 1.00694311,7.89417459 1.00694311,8.99826498 L1.00694311,15.001735 C1.00694311,16.1058254 1.90237361,17.0008675 3.00694311,17.0008675 L15.0069431,17.0008675 C16.1115126,17.0008675 17.0069431,16.1058254 17.0069431,15.001735 L17.0069431,9.00520507 C17.0069431,7.90111468 16.1115126,7.00607258 15.0069431,7.00607258 L14.0069431,7.00607258 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.006943, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-9.006943, -12.000000) " />
                                            <rect fill="#000000" opacity="0.3" transform="translate(14.000000, 12.000000) rotate(-270.000000) translate(-14.000000, -12.000000) " x="13" y="6" width="2" height="12" rx="1" />
                                            <path d="M21.7928932,9.79289322 C22.1834175,9.40236893 22.8165825,9.40236893 23.2071068,9.79289322 C23.5976311,10.1834175 23.5976311,10.8165825 23.2071068,11.2071068 L20.2071068,14.2071068 C19.8165825,14.5976311 19.1834175,14.5976311 18.7928932,14.2071068 L15.7928932,11.2071068 C15.4023689,10.8165825 15.4023689,10.1834175 15.7928932,9.79289322 C16.1834175,9.40236893 16.8165825,9.40236893 17.2071068,9.79289322 L19.5,12.0857864 L21.7928932,9.79289322 Z" fill="#000000" fill-rule="nonzero" transform="translate(19.500000, 12.000000) rotate(-90.000000) translate(-19.500000, -12.000000) " />
                                        </g>
                                    </svg>
                                </span>
                                <span className="kt-menu__link-text">Logout</span></a>
                        </li> */}

                    </ul>
                    <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                        <div className="ps__thumb-x" tabIndex={0} style={{ left: 0, width: 0 }}></div>
                    </div>
                    <div className="ps__rail-y" style={{ top: 0, height: 219, right: 3 }}>
                        <div className="ps__thumb-y" tabIndex={0} style={{ top: 0, height: 40 }}></div>
                    </div>
                </div>
            </div>
            {/* <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand" kt-hidden-height="65" >
                <div className="kt-aside__brand-logo fixed-bottom bg-transparent">
                    <a href="javascript:;">
                        <img alt="Logo" src={BrandingLogo} style={{ height: 30, display: 'inherit', opacity: 0.3 }} />
                    </a>
                </div>
            </div> */}
        </div>
    )
}

const renderUserManagementOptions = () => {
    return (
        <React.Fragment>
            <li className="kt-menu__item" aria-haspopup="true" role="menuitem">
                <a href={CONSTANT.url.user} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fill-rule="nonzero"></path>
                                <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3"></path>
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">User Management</span></a>
            </li>
        </React.Fragment >
    )
}

const renderDatabseManagementOptions = () => {
    return (
        <React.Fragment>
            <li className="kt-menu__item  kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover" role="menuitem">
                <a href={CONSTANT.url.meatcutList} className="kt-menu__link kt-menu__toggle">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fill-rule="nonzero"></path>
                                <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3"></path>
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">Databse Management</span>
                    <i className="kt-menu__ver-arrow la la-angle-right"></i>
                </a>
                <div className="kt-menu__submenu "><span className="kt-menu__arrow"></span>
                    <ul className="kt-menu__subnav">
                        <li className="kt-menu__item" aria-haspopup="true" role="menuitem"><a href={CONSTANT.url.meatcutList} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Meat Products</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true" role="menuitem"><a href={CONSTANT.url.muscleList} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Muscle</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true" role="menuitem"><a href={CONSTANT.url.definitionList} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Definition</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true" role="menuitem"><a href={CONSTANT.url.packagingList} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Packaging</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true" role="menuitem"><a href={CONSTANT.url.checklist} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Aus Meat Checklist Guide</span></a></li>
                    </ul>
                </div>
            </li>
        </React.Fragment>
    )
}

const renderPDFManagementOptions = () => {
    return (
        <React.Fragment>
            <li className="kt-menu__item" aria-haspopup="true" role="menuitem">
                <a href={CONSTANT.url.pdfManagement} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fill-rule="nonzero"></path>
                                <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3"></path>
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">Pdf Management</span></a>
            </li>
        </React.Fragment>
    )
}

const renderInventoryManagementOptions = () => {
    return (
        <React.Fragment>
            <li className="kt-menu__item" aria-haspopup="true" role="menuitem">
                <a href={CONSTANT.url.inventoryManagement} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fill-rule="nonzero"></path>
                                <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3"></path>
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">Inventory Management</span></a>
            </li>
        </React.Fragment>
    )
}

const renderSalesManagementOptions = () => {
    return (
        <React.Fragment>
            <li className="kt-menu__item" aria-haspopup="true" role="menuitem">
                <a href={CONSTANT.url.salesManagement} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fill-rule="nonzero"></path>
                                <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3"></path>
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">Sales Management</span></a>
            </li>
        </React.Fragment>
    )
}

const renderModelManagementOptions = () => {
    return (
        <React.Fragment>
            <li className="kt-menu__item" aria-haspopup="true" role="menuitem">
                <a href={CONSTANT.url.threedModel} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fill-rule="nonzero"></path>
                                <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3"></path>
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">3d Model Management</span></a>
            </li>
        </React.Fragment>
    )
}

// const renderCMSPagesOption = () => {
//     return (
//         <React.Fragment>
//             <li className="kt-menu__item  kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
//                 <a href={CONSTANT.url.aboutUs} className="kt-menu__link kt-menu__toggle">
//                     <span className="kt-menu__link-icon">
//                         <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
//                             <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
//                                 <rect x="0" y="0" width="24" height="24" />
//                                 <path d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z" fill="#000000" opacity="0.3" />
//                                 <path d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z" fill="#000000" />
//                             </g>
//                         </svg>
//                     </span>
//                     <span className="kt-menu__link-text">CMS Pages</span>
//                     <i className="kt-menu__ver-arrow la la-angle-right"></i>
//                 </a>
//                 <div className="kt-menu__submenu "><span className="kt-menu__arrow"></span>
//                     <ul className="kt-menu__subnav">
//                         <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.carcaseList} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Carcase & Evaluation</span></a></li>
//                         <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.aboutUs} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">About Aus Meat</span></a></li>
//                         <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.categories} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Category Management</span></a></li>
//                         <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.faq} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Support</span></a></li>
//                         {/* <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.termCondition} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Terms & Conditions</span></a></li> */}
//                         {/* <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.privacyPolicy} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Privacy Policy</span></a></li> */}
//                         {/* <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.faq} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">FAQ</span></a></li> */}
//                         {/* <li className="kt-menu__item" aria-haspopup="true"><a href={CONSTANT.url.splash} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Splash Management</span></a></li> */}
//                     </ul>
//                 </div>
//             </li>
//         </React.Fragment>
//     )
// }

const renderCMSPagesOptions = () => {
    return (
        <React.Fragment>
            <li className="kt-menu__item  kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover" role="menuitem">
                <a href={CONSTANT.url.aboutUs} className="kt-menu__link kt-menu__toggle">
                    <span className="kt-menu__link-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z" fill="#000000" opacity="0.3" />
                                <path d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z" fill="#000000" />
                            </g>
                        </svg>
                    </span>
                    <span className="kt-menu__link-text">CMS Pages</span>
                    <i className="kt-menu__ver-arrow la la-angle-right"></i>
                </a>
                <div className="kt-menu__submenu "><span className="kt-menu__arrow"></span>
                    <ul className="kt-menu__subnav">
                        <li className="kt-menu__item" aria-haspopup="true" role="menuitem"><a href={CONSTANT.url.carcaseList} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Carcase & Evaluation</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true" role="menuitem"><a href={CONSTANT.url.aboutUs} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">About Aus Meat</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true" role="menuitem"><a href={CONSTANT.url.categories} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Category Management</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true" role="menuitem"><a href={CONSTANT.url.faq} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Support</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true" role="menuitem"><a href={CONSTANT.url.termCondition} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Terms & Conditions</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true" role="menuitem"><a href={CONSTANT.url.foodIntegrity} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Food Safety & Integrity</span></a></li>
                        <li className="kt-menu__item" aria-haspopup="true" role="menuitem"><a href={CONSTANT.url.marketingResearch} className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Marketing Research & Development</span></a></li>
                        <li className="kt-menu__item  kt-menu__item--parent" aria-haspopup="true" role="menuitem"><span className="kt-menu__link"><span className="kt-menu__link-text">CMS Pages</span></span></li>
                        {/* <li className="kt-menu__item  kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover"><a href="javascript:;" className="kt-menu__link kt-menu__toggle"><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Aus Meat Language</span><i className="kt-menu__ver-arrow la la-angle-right"></i></a>
                            <div className="kt-menu__submenu "><span className="kt-menu__arrow"></span>
                                <ul className="kt-menu__subnav">
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/controls/base.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Labelling</span></a></li>
                                </ul>
                            </div>
                        </li> */}
                        {/* <li className="kt-menu__item  kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover"><a href="javascript:;" className="kt-menu__link kt-menu__toggle"><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Form Widgets</span><i className="kt-menu__ver-arrow la la-angle-right"></i></a>
                            <div className="kt-menu__submenu "><span className="kt-menu__arrow"></span>
                                <ul className="kt-menu__subnav">
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/widgets/bootstrap-datepicker.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Datepicker</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/widgets/bootstrap-datetimepicker.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Datetimepicker</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/widgets/bootstrap-timepicker.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Timepicker</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/widgets/bootstrap-daterangepicker.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Daterangepicker</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/widgets/tagify.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Tagify</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/widgets/bootstrap-touchspin.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Touchspin</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/widgets/bootstrap-maxlength.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Maxlength</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/widgets/bootstrap-switch.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Switch</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/widgets/bootstrap-multipleselectsplitter.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Multiple Select Splitter</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/widgets/bootstrap-select.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Bootstrap Select</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/widgets/select2.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Select2</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/widgets/typeahead.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Typeahead</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/widgets/nouislider.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">noUiSlider</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/widgets/form-repeater.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Form Repeater</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/widgets/ion-range-slider.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Ion Range Slider</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/widgets/input-mask.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Input Masks</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/widgets/autosize.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Autosize</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/widgets/clipboard.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Clipboard</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/widgets/recaptcha.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Google reCaptcha</span></a></li>
                                </ul>
                            </div>
                        </li> */}
                        {/* <li className="kt-menu__item  kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover"><a href="javascript:;" className="kt-menu__link kt-menu__toggle"><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Form Text Editors</span><i className="kt-menu__ver-arrow la la-angle-right"></i></a>
                            <div className="kt-menu__submenu "><span className="kt-menu__arrow"></span>
                                <ul className="kt-menu__subnav">
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/editors/tinymce.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">TinyMCE</span></a></li>
                                    <li className="kt-menu__item  kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover"><a href="javascript:;" className="kt-menu__link kt-menu__toggle"><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">CKEditor</span><i className="kt-menu__ver-arrow la la-angle-right"></i></a>
                                        <div className="kt-menu__submenu "><span className="kt-menu__arrow"></span>
                                            <ul className="kt-menu__subnav">
                                                <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/editors/ckeditor-classic.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--line"><span></span></i><span className="kt-menu__link-text">CKEditor Classic</span></a></li>
                                                <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/editors/ckeditor-inline.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--line"><span></span></i><span className="kt-menu__link-text">CKEditor Inline</span></a></li>
                                                <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/editors/ckeditor-balloon.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--line"><span></span></i><span className="kt-menu__link-text">CKEditor Balloon</span></a></li>
                                                <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/editors/ckeditor-balloon-block.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--line"><span></span></i><span className="kt-menu__link-text">CKEditor Balloon Block</span></a></li>
                                                <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/editors/ckeditor-document.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--line"><span></span></i><span className="kt-menu__link-text">CKEditor Document</span></a></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/editors/quill.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Quill Text Editor</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/editors/summernote.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Summernote WYSIWYG</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/editors/bootstrap-markdown.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Markdown Editor</span></a></li>
                                </ul>
                            </div>
                        </li> */}
                        {/* <li className="kt-menu__item  kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover"><a href="javascript:;" className="kt-menu__link kt-menu__toggle"><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Form Layouts</span><i className="kt-menu__ver-arrow la la-angle-right"></i></a>
                            <div className="kt-menu__submenu "><span className="kt-menu__arrow"></span>
                                <ul className="kt-menu__subnav">
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/layouts/default-forms.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Default Forms</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/layouts/multi-column-forms.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Multi Column Forms</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/layouts/action-bars.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Basic Action Bars</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/layouts/sticky-action-bar.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Sticky Action Bar</span></a></li>
                                </ul>
                            </div>
                        </li> */}
                        {/* <li className="kt-menu__item  kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover"><a href="javascript:;" className="kt-menu__link kt-menu__toggle"><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Form Validation</span><i className="kt-menu__ver-arrow la la-angle-right"></i></a>
                            <div className="kt-menu__submenu "><span className="kt-menu__arrow"></span>
                                <ul className="kt-menu__subnav">
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/validation/states.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Validation States</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/validation/form-controls.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Form Controls</span></a></li>
                                    <li className="kt-menu__item " aria-haspopup="true"><a href="crud/forms/validation/form-widgets.html" className="kt-menu__link "><i className="kt-menu__link-bullet kt-menu__link-bullet--dot"><span></span></i><span className="kt-menu__link-text">Form Widgets</span></a></li>
                                </ul>
                            </div>
                        </li> */}
                    </ul>
                </div>
            </li>
        </React.Fragment>
    )
}

// const renderMyProfile = () => {
//     return (
//         <React.Fragment>
//             {/* Profile management */}
//             <li className="kt-menu__item" aria-haspopup="true">
//                 <a href={CONSTANT.url.profile} className="kt-menu__link ">
//                     <span className="kt-menu__link-icon">
//                         <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
//                             <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
//                                 <polygon points="0 0 24 0 24 24 0 24"></polygon>
//                                 <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fill-rule="nonzero"></path>
//                                 <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3"></path>
//                             </g>
//                         </svg>
//                     </span>
//                     <span className="kt-menu__link-text">My Profile</span></a>
//             </li>
//             {/* Profile management */}
//         </React.Fragment >
//     )
// }

export default Sidebar;