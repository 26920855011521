import * as React from 'react';
import firebase from "firebase/app";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2/dist/sweetalert2'
import 'sweetalert2/src/sweetalert2.scss';

import Loader from '../../component/common/Loader';
import DropzoneImg from './../common/DropzoneImg';
import { onChange, validateForm, getStorage } from './../../utils';
import CONSTANT from '../../constant';
import { db } from './../../firebase/index';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: props.id,
            detail,
            currentUserId: undefined,
            showLoader: false,

            carcaseCategory: { name: 'carcaseCategory', value: detail && detail.carcaseCategoryId ? detail.carcaseCategoryId : '', error: '', isRequired: true, options: [] },
            carcaseSubCategory: { name: 'carcaseSubCategory', value: detail && detail.carcaseSubCategoryId ? detail.carcaseSubCategoryId : '', error: '', isRequired: true, options: [] },
            definition: { name: 'definition', value: detail && detail.definition ? detail.definition : '', error: '', isRequired: true, options: [] },
            description: { name: 'description', value: detail && detail.description ? detail.description : '', error: '', isRequired: true, options: [] },
            categoryCypher: { name: 'categoryCypher', value: detail && detail.categoryCypher ? detail.categoryCypher : '', error: '', isRequired: true, options: [] },

            productUrl: detail && detail.productUrl ? detail.productUrl : undefined,
            productFileName: detail && detail.productFileName ? detail.productFileName : undefined,
        }
    }

    componentDidMount() {
        const ud = getStorage(CONSTANT.keys.userDetail);
        this.setState({ currentUserId: ud.uid });
        this.loadList();
    }

    public render() {
        const {
            showLoader, carcaseCategory, carcaseSubCategory, definition, description, categoryCypher, productUrl
        } = this.state;
        // const url = id ? CONSTANT.url.catalogueUpdates.replace(':id', id) : '';
        return (
            <React.Fragment>
                <form onSubmit={this.onSubmit}>

                    <div className="row">

                        <div className="col-md-4 form-group">
                            <label className="col col-form-label">Carcase Category</label>
                            <div className="col-lg-12 form-group">
                                <select
                                    className={carcaseCategory.error.length > 0 ? "form-control is-invalid" : "form-control mr-1"}
                                    name={carcaseCategory.name}
                                    value={carcaseCategory.value}
                                    onChange={this.onChange}>
                                    <option value="">Select carcase Category</option>
                                    {
                                        carcaseCategory.options.map(function (item: any, index: any) {
                                            return (
                                                <option key={index} id={item.id} value={item.id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4 form-group">
                            <label className="col col-form-label">Sub Category</label>
                            <div className="col-lg-12 form-group">
                                <select
                                    className={carcaseSubCategory.error.length > 0 ? "form-control is-invalid" : "form-control mr-1"}
                                    name={carcaseSubCategory.name}
                                    value={carcaseSubCategory.value}
                                    onChange={this.onChange}>
                                    <option value="">Select sub category</option>
                                    {
                                        carcaseSubCategory.options.map(function (item: any, index: any) {
                                            return (
                                                <option key={index} id={item.id} value={item.id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4 form-group">
                            <label className="col col-form-label">Definition</label>
                            <div className="col-lg-12 form-group">
                                <input
                                    type="text"
                                    className={definition.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                    placeholder="Enter definition example: 0-5"
                                    name={definition.name}
                                    value={definition.value}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="col col-form-label">Description</label>
                                <div className="col-lg-12 form-group">
                                    <textarea
                                        className={description.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        rows={6}
                                        placeholder="Write text here"
                                        name={description.name}
                                        value={description.value}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="col col-form-label">Category / Cipher</label>
                                <div className="col-lg-12 form-group">
                                    <textarea
                                        className={categoryCypher.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        rows={6}
                                        placeholder="Write text here"
                                        name={categoryCypher.name}
                                        value={categoryCypher.value}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            {productUrl ?
                                <div className="">
                                    <label className="col col-form-label">Product Image</label>
                                </div>
                                :
                                <div className="form-group">
                                    <label className="col col-form-label ml-0">Product Image</label>
                                    <DropzoneImg
                                        acceptFile={this.onFilesProductImage}
                                        title={"Drag & Drop or Browse file"}
                                    />
                                </div>
                            }
                            {productUrl &&
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    <strong>{productUrl}</strong>
                                    <button type="button" className="btn close" aria-label="Close" onClick={this.onDeleteProductImage}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-10" />
                        <div className="col-lg-2 mr-0">
                            <button type="submit" className="btn btn-primary btn-sm btn-block">Save</button>
                        </div>
                    </div>

                </form>
                {
                    showLoader && <Loader />
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;

        onChange(this, name, value);
    }

    typeaheadOnChange = (name: string, e: any) => {
        let value = e;
        if (e.length > 0 && e[0].customOption) {
            value = [{ name: e[0].name }];
        }
        onChange(this, name, value);
    }

    loadList = async () => {
        this.setState({ showLoader: true });
        const carcaseCategoryListArray: any[] = [];
        const carcaseSubCategoryListArray: any[] = [];
        const levelTwoListArray: any[] = [];
        const levelThreeListArray: any[] = [];

        const carcaseCategoryQuerySnapshot = await db.collection(CONSTANT.collection.cmsCarcaseCategory).get();
        carcaseCategoryQuerySnapshot.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            carcaseCategoryListArray.push(data);
        });

        const arcaseSubCategoryQuerySnapshot = await db.collection(CONSTANT.collection.cmsCarcaseSubCategory).get();
        arcaseSubCategoryQuerySnapshot.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            carcaseSubCategoryListArray.push(data);
        });

        const levelTwoQuerySnapshot = await db.collection(CONSTANT.collection.cmsCategoryLevel2).get();
        levelTwoQuerySnapshot.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            levelTwoListArray.push(data);
        });

        const levelThreeQuerySnapshot = await db.collection(CONSTANT.collection.cmsCategoryLevel3).get();
        levelThreeQuerySnapshot.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            levelThreeListArray.push(data);
        });


        this.setState({
            showLoader: false,
            carcaseCategory: { ...this.state.carcaseCategory, options: carcaseCategoryListArray },
            carcaseSubCategory: { ...this.state.carcaseSubCategory, options: carcaseSubCategoryListArray },
        });

    }

    onFilesProductImage = (files: any) => {
        var file = files[0];
        this.handleProductImageUpload(file);
    }

    handleProductImageUpload = (file: any) => {
        this.setState({ showLoader: true });

        const extArray = file.name.split('.');
        const ext = extArray[extArray.length - 1];
        const originalFilename = extArray[0];
        let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
        const fileDirectory = `${CONSTANT.fileDirectory.productImage}/${fileName}`;

        const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

        uploadTask.on("state_changed", (snapshot: any) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.setState({ progress });
        },
            (error: any) => {
                console.log(error);
            },
            () => {
                firebase.app().storage()
                    .ref(CONSTANT.fileDirectory.productImage)
                    .child(fileName)
                    .getDownloadURL()
                    .then(url => {
                        this.setState({ productUrl: url, productFileName: fileName, showLoader: false });
                    });
            }
        );
    };

    onDeleteProductImage = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete product image?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result: any) => {
            if (result.value) {
                this.deleteProductImage();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your product image is safe :)',
                    'error'
                )
            }
        })
    }

    deleteProductImage = () => {
        this.setState({ showLoader: true });
        firebase.app().storage()
            .ref(CONSTANT.fileDirectory.productImage)
            .child(this.state.productFileName)
            .delete()
            .then(url => {
                this.setState({ productFileName: "", productUrl: "", showLoader: false });
                Swal.fire(
                    'Deleted!',
                    'Product image has been deleted.',
                    'success'
                )
            });
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const {
                carcaseCategory, carcaseSubCategory, definition, description, categoryCypher, productUrl, productFileName
            } = this.state;

            if (!productUrl) return toast.error("Please upload 3d model.");

            const model: any = {
                carcaseCategoryId: carcaseCategory.value,
                carcaseSubCategoryId: carcaseSubCategory.value,
                definition: definition.value,
                description: description.value,
                categoryCypher: categoryCypher.value,
                productUrl: productUrl,
                productFileName: productFileName
            };
            if (this.state.id) {
                model.id = this.state.id;
            }
            this.props.onSubmit(model);
        }

    }

}

export default AddComponent;