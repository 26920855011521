import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LoginBG from './../../assets/images/bg_login_1920.png';
import Loader from '../../component/common/Loader';
import { onChange, validateForm, setStorage, removeStorage, getStorage } from '../../utils';
import CONSTANT from '../../constant';
import { auth, db } from './../../firebase/index';

class Login extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            email: { name: 'email', value: '', error: '', isRequired: true },
            password: { name: 'password', value: '', error: '', isRequired: true },
            remember: { name: 'remember', value: false, error: '', isRequired: false },
            showLoader: false
        };
    }

    componentDidMount() {
        const rememberDetail = getStorage(CONSTANT.keys.remember);
        if (rememberDetail) {
            onChange(this, this.state.email.name, rememberDetail.email)
            onChange(this, this.state.password.name, rememberDetail.password);
            onChange(this, this.state.remember.name, true);
        }
    }

    public render() {
        const { email, password, showLoader, remember } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="kt-grid kt-grid--ver kt-grid--root">

                    <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v1" id="kt_login">
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">

                            <div className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside" style={{ backgroundImage: `url(${LoginBG})` }}>
                                <div className="kt-grid__item">
                                    {/* <a href="#" className="kt-login__logo">
                                        <img src={Logo4} />
                                    </a> */}
                                </div>
                                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                                    <div className="kt-grid__item kt-grid__item--middle">

                                    </div>
                                </div>
                                <div className="kt-grid__item">
                                    <div className="kt-login__info">
                                        <div className="kt-login__copyright">
                                            {/* Copyright © {new Date().getFullYear()} Aus Meat */}
                                            Copyright © {new Date().getFullYear()} <b>Aus Meat</b>. All rights reserved.
								        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">

                                <div className="kt-login__body">

                                    <div className="kt-login__form">
                                        {/* <div className="kt-login__title">
                                            <h3>Log In</h3>
                                        </div> */}
                                        <h3>Log In</h3>

                                        <form className="kt-form" onSubmit={this.submitForm}>
                                            <div className="form-group">
                                                <input
                                                    className={email.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    type="text"
                                                    placeholder="Email"
                                                    name={email.name}
                                                    value={email.value}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    className={password.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    type="password"
                                                    placeholder="Password"
                                                    name={password.name}
                                                    value={password.value}
                                                    onChange={this.onChange}
                                                />
                                            </div>

                                            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-5">
                                                <a href={CONSTANT.url.forgotPassword} className="kt-link kt-login__link-forgot">Forgot Password ?</a>
                                                <div className="checkbox-inline text-center">
                                                    <label className="checkbox checkbox-outline m-0 text-muted">
                                                        <input type="checkbox" name={remember.name} className="mr-2" onChange={this.onRemember} checked={remember.value} />
                                                        <span className="pt-5">Remember me</span>
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="kt-login__actions">
                                                <button type="submit" id="kt_login_signin_submit" className="btn btn-primary btn-lg btn-block btn-elevate kt-login__btn-primary">
                                                    Log In
                                                </button>
                                            </div>

                                        </form>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
    }

    private onRemember = (e: any) => {
        const name = e.target.name;
        const value = e.target.checked;

        onChange(this, name, value);
    }

    private submitForm = async (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const st = this.state;
            const model = {
                email: st.email.value,
                password: st.password.value
            };
            this.setState({ showLoader: true });
            auth.signInWithEmailAndPassword(model.email, model.password).then(async (result: any) => {
                if (result) {
                    const user = await db.collection(CONSTANT.collection.users).doc(`${result.user.uid}`).get();
                    let userDetail = user.data();
                    if (userDetail && userDetail.role === "admin") {
                        this.setState({ showLoader: false });
                        setStorage(CONSTANT.keys.userDetail, { uid: userDetail.uid, displayName: `${userDetail.firstName} ${userDetail.lastName}`, email: userDetail.email, profileUrl: userDetail.profileUrl ? userDetail.profileUrl : '' });
                        if (this.state.remember.value) {
                            setStorage(CONSTANT.keys.remember, { email: st.email.value, password: st.password.value });
                        } else {
                            removeStorage(CONSTANT.keys.remember);
                        }
                        window.location.href = CONSTANT.url.dashboard;
                    } else {
                        this.setState({ showLoader: false });
                        toast.error("You are not authorized to login");
                    }
                }
            }).catch((error: any) => {
                this.setState({ showLoader: false });
                if (error && error.code === "auth/user-not-found") {
                    toast.error(error.message);
                }
                if (error && error.code === "auth/wrong-password") {
                    toast.error("Invalid Password");
                }
                if (error && error.code === "auth/invalid-email") {
                    toast.error(error.message);
                }
            });
        }
    }
}

export default Login;