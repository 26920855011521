import * as React from 'react';
import Swal from 'sweetalert2/dist/sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import PaginationComponent from "react-reactstrap-pagination";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Header from '../../component/common/Header';
import Sidebar from '../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import Loader from './../../component/common/Loader';
import ModalWindow from './../../component/common/ModalWindow';
import PDFDetailComponent from './../../component/pdf-management/PDFDetail';
import CONSTANT from './../../constant';
import { onChange, formatBytes } from './../../utils';
import { db } from './../../firebase/index';

class PdfList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            list: undefined,
            page: 1,
            limit: 10,
            sort: 'desc',
            count: 0,
            showLoader: false,
            keyword: { name: 'keyword', value: '', error: '', isRequired: false },
            showPDFModal: false,
            pdfDetail: undefined,
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {
        const { showLoader, showPDFModal, pdfDetail } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                {this.renderHeader()}
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showPDFModal && <ModalWindow
                        className="modal-lg"
                        title={`${pdfDetail ? 'Edit Carcase Sub Category' : 'Add New Carcase Sub Category'}`}
                        backdrop="static"
                        toggleModal={this.onCancel}>
                        <PDFDetailComponent
                            onSubmit={this.onSubmitPdf}
                            onCancel={this.onCancel}
                            detail={pdfDetail}
                        />
                    </ModalWindow>
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderHeader = () => {
        return (
            <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">
                            Pdf Management
                        </h3>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { list, limit, count, keyword } = this.state;
        const ps: any = count / limit;
        const pageSize = parseInt(ps);
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">

                    <div className="col-md-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    {/* <div className="row">
                                        <div className="col-md-12 mt-2">Rows per page</div>
                                    </div>
                                    <div className="row ml-2">
                                        <div className="col-md-12 pull-left">
                                            <select name="kt_table_1_length" aria-controls="kt_table_1" className="custom-select custom-select-sm" onChange={this.onChange}>
                                                <option value={10}>10</option>
                                                <option value={20}>20</option>
                                                <option value={50}>50</option>
                                                <option value={100}>100</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    <div className="kt-input-icon kt-input-icon--right kt-subheader__search" style={{ width: 300 }}>
                                        <input name={keyword.name} type="text" className="form-control" placeholder="Search" value={keyword.value} onChange={this.searchVendor} />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                            <span className="svg-icon svg-icon-lg">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24"></rect>
                                                        <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                        <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero"></path>
                                                    </g>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="kt-portlet__head-toolbar">
                                    {/* <div className="kt-input-icon kt-input-icon--right kt-subheader__search" style={{ width: 300 }}>
                                        <input name={keyword.name} type="text" className="form-control" placeholder="Search" value={keyword.value} onChange={this.searchVendor} />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                            <span className="svg-icon svg-icon-lg">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24"></rect>
                                                        <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                        <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero"></path>
                                                    </g>
                                                </svg>
                                            </span>
                                        </span>
                                    </div> */}
                                    <button className="btn btn-brand btn-elevate btn-icon-sm" onClick={this.onAddNew}>
                                        Add New PDF
                                    </button>
                                </div>
                            </div>
                            <div className="kt-portlet__body">

                                <div className="kt-section mb-0">
                                    <div className="kt-section__content">
                                        {list !== undefined && this.renderTable()}
                                    </div>
                                </div>

                                <div className="h-100 row align-space-between">
                                    <div className="col-12 d-flex justify-content-end mt-4">
                                        {count > limit &&
                                            <PaginationComponent
                                                classname="float-right"
                                                totalItems={count}
                                                pageSize={pageSize}
                                                onSelect={this.handleSelected}
                                            />
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    renderTable = () => {
        const { list } = this.state;
        const columns = [{
            dataField: 'title',
            text: 'PDF',
            headerStyle: () => { return { textAlign: 'center' } },
            style: () => { return { textAlign: 'center' } },
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return <a href={row.pdfUrl} target="_blank" rel="noreferrer"><i className="kt-nav__link-icon text-primary fas fa-file-pdf"></i></a>
            },
        }, {
            dataField: 'title',
            text: 'Title',
            headerStyle: () => { return { textAlign: 'center' } },
            style: () => { return { textAlign: 'center' } },
            sort: true,
        }, {
            dataField: 'modifiedOn',
            text: 'Last Modified',
            headerStyle: () => { return { textAlign: 'center' } },
            style: () => { return { textAlign: 'center' } },
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return <span>{moment.unix(row.modifiedOn).format("MM/DD/YY")}</span>
            },
            sort: true
        }, {
            dataField: 'fileSize',
            text: 'File Size',
            headerStyle: () => { return { textAlign: 'center' } },
            style: () => { return { textAlign: 'center' } },
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return <span>{`${row.fileSize ? formatBytes(row.fileSize) : ''}`}</span>
            },
            sort: true
        }, {
            dataField: 'isActive',
            text: 'Status',
            headerStyle: () => { return { textAlign: 'center' } },
            style: () => { return { textAlign: 'center' } },
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return <span>{row.isActive ? 'Active' : 'Inactive'}</span>
            },
            sort: true
        }, {
            dataField: 'country',
            text: 'Countries Available',
            headerStyle: () => { return { textAlign: 'center' } },
            style: () => { return { textAlign: 'center' } },
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return <span>{`${row.country ? row.country : ''}`}</span>
            },
            sort: true
        }, {
            dataField: 'View',
            text: '',
            headerStyle: () => { return { textAlign: 'center' } },
            style: () => { return { textAlign: 'center' } },
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return <span><span className="btn kt-font-brand" onClick={() => this.viewPdf(row)}>View & Edit</span></span>
            },
        }, {
            dataField: 'Delete',
            text: '',
            headerStyle: () => { return { textAlign: 'center' } },
            style: () => { return { textAlign: 'center' } },
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return (
                    <span className="btn kt-font-brand" onClick={() => this.deleteVendor(row)}>Delete</span>
                )
            },
        }];
        return (<React.Fragment>
            <BootstrapTable defaultSortDirection="asc" bootstrap4 keyField='index' data={list} columns={columns} />
        </React.Fragment>)
    }

    onChange = (e: any) => {
        const value = e.target.value;

        this.setState({ limit: Number(value) }, () => {
            this.loadList();
        });
    }

    onAddNew = () => {
        this.setState({ pdfDetail: undefined, showPDFModal: true });
    }

    onCancel = () => {
        this.setState({ showPDFModal: false });
    }

    loadList = async () => {
        const { page, limit } = this.state;
        try {
            this.setState({ showLoader: true });
            let startLimit = page > 1 ? (page - 1) * limit : page * limit;
            let users: any[] = [];

            const countSnap: any = await db.collection(CONSTANT.collection.pdfManagement).where('isActive', '==', true).orderBy('modifiedOn', 'desc').get();

            const first = db.collection(CONSTANT.collection.pdfManagement).where('isActive', '==', true).orderBy('modifiedOn', 'desc').limit(startLimit);
            const snapshot = await first.get();

            snapshot.forEach((doc: any) => {
                let data = doc.data();
                data.id = doc.id;
                users.push(data);
            });

            // Get the last document
            const last = snapshot.docs[snapshot.docs.length - 1];
            if (page > 1) {
                const userQuerySnapshot = await db.collection(CONSTANT.collection.pdfManagement).where('isActive', '==', true).startAfter(last.data().modifiedOn).limit(limit).get();
                users = [];
                userQuerySnapshot.forEach((doc: any) => {
                    let data = doc.data();
                    data.id = doc.id;
                    users.push(data);
                });
            }

            this.setState({ list: users, count: countSnap.size, showLoader: false });
        } catch (err) {
            console.log(err);
        }
    }

    searchVendor = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value, async () => {
            let result = [];
            let titleList: any[] = [];

            const titleQuerySnapshot = await db.collection(CONSTANT.collection.pdfManagement).where('isActive', '==', true).orderBy('title').startAt(`${value}`).endAt(value + '\uf8ff').get();
            titleQuerySnapshot.forEach((doc: any) => {
                let data = doc.data();
                data.id = doc.id;
                titleList.push(data);
            });
            result = titleList;

            this.setState({ list: result })
        });
    }

    onPageChange = (e: any) => {
        const page = parseInt(e.target.dataset.page, 10);
        this.setState({ page }, () => {
            window.scrollTo(0, 0);
            this.loadList();
        });
    }

    handleSelected = (selectedPage: any) => {
        this.setState({ page: selectedPage }, () => {
            window.scrollTo(0, 0);
            this.loadList();
        });
    }

    viewPdf(item: any) {
        this.setState({ pdfDetail: item, showPDFModal: true });
    }

    deleteVendor(item: any) {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete ${item.title}'?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then(async (result: any) => {
            if (result.value) {
                this.setState({ showLoader: true });
                const updateMdel = { isActive: false };
                await db.collection(CONSTANT.collection.pdfManagement).doc(`${item.id}`).set(updateMdel, { merge: true });
                this.setState({ showLoader: false });
                this.loadList();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your pdf is safe :)',
                    'error'
                )
            }
        })
    }

    onSubmitPdf = async (reqObj: any) => {
        let model = { ...reqObj };
        model.isActive = true;
        model.modifiedOn = moment().unix();
        delete model.id;

        this.onCancel();
        this.setState({ showLoader: true });
        let arrTemp: any = [];
        let animalData: any = await db.collection(CONSTANT.collection.pdfManagement).where("titleFlag", "==", reqObj.title.toLowerCase()).get();
        animalData.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            arrTemp.push(data);
        });
        if (reqObj.id) {
            this.setState({ showLoader: true });

            const user = await db.collection(CONSTANT.collection.pdfManagement).doc(`${reqObj.id}`).get();
            if (!user.exists) return toast.error("PDF not found.");

            await db.collection(CONSTANT.collection.pdfManagement).doc(`${reqObj.id}`).set(model, { merge: true });
            this.setState({ showLoader: false });
            this.loadList();
        } else {
            if (arrTemp.length === 0) {
                await db.collection(CONSTANT.collection.pdfManagement).add(model);
            } else {
                this.setState({ showLoader: false });
                toast.error('PDF already exist');
                return;
            }
        }
        this.setState({ showLoader: false }, () => this.loadList());
    }
}

export default PdfList;