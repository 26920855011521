import * as React from "react";
import firebase from "firebase/app";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import Loader from "../../component/common/Loader";
import { onChange, validateForm } from "../../utils";
import Dropzone from "./../common/DropzoneModel";
import DropzoneImg from "./../common/DropzoneImg";
import CONSTANT from "../../constant";
import { db } from "./../../firebase/index";

class AddComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    const detail = props.detail;
    this.state = {
      id: detail ? detail.id : undefined,
      showLoader: false,
      description: {
        name: "description",
        value: detail ? detail.description : "",
        error: "",
        isRequired: true,
      },
      meatProduct: {
        name: "meatProduct",
        value: detail && detail.meatProductId ? detail.meatProductId : "",
        error: "",
        isRequired: true,
        options: [],
      },
      modelUrl: detail && detail.modelUrl ? detail.modelUrl : "",
      carcaseUrl: detail && detail.carcaseUrl ? detail.carcaseUrl : "",
      intersection: detail && detail.intersection ? detail.intersection : "",
    };
  }

  async componentDidMount() {
    const meatcutListArray: any[] = [];
    const meactcutQuerySnapshot = await db
      .collection(CONSTANT.collection.dbMeatcut)
      .where("isActive", "==", true)
      .orderBy("modifiedOn", "asc")
      .get();
    meactcutQuerySnapshot.forEach((doc: any) => {
      let data = doc.data();
      data.id = doc.id;
      meatcutListArray.push(data);
    });
    console.log("meatcutListArray ", meatcutListArray);

    this.setState({
      meatProduct: { ...this.state.meatProduct, options: meatcutListArray },
    });
  }

  public render() {
    const { description, meatProduct, modelUrl, carcaseUrl, showLoader } =
      this.state;
    return (
      <React.Fragment>
        <form onSubmit={this.onSubmit}>
          <div className="row mb-2">
            <div className="col-lg-12 form-group">
              <label className="col col-form-label">Description</label>
              <input
                type="text"
                className={
                  description.error.length > 0
                    ? "form-control is-invalid"
                    : "form-control"
                }
                placeholder="Enter description"
                name={description.name}
                value={description.value}
                onChange={this.onChange}
              />
            </div>
            {/* <div className="col-lg-12 form-group">
                            <label className="col col-form-label">Meat Product</label>
                            <select
                                className={meatProduct.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                name={meatProduct.name}
                                value={meatProduct.value}
                                onChange={this.onChange}
                            >
                                <option value="">Select meat product</option>
                                {
                                    meatProduct.options.map(function (item: any, ind: any) {
                                        return (
                                            <option key={ind} id={item.id} value={item.id}>{item.meatcutName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div> */}
            <div className="col-lg-12 form-group">
              <label className="col col-form-label">Meat Product</label>
              <Typeahead
                id="ta-skills"
                allowNew={false}
                labelKey="meatcutName"
                name={meatProduct.name}
                selected={meatProduct.value}
                multiple={true}
                options={meatProduct.options}
                onChange={(e: any) =>
                  this.typeaheadOnChangeSelect(meatProduct.name, e)
                }
                placeholder="Select Meat Product"
                isInvalid={meatProduct.error.length > 0}
              />
            </div>
          </div>
          <div className="row mb-2 d-none">
            <div className="col-md-6">
              {modelUrl ? (
                <div className="">
                  <label className="col col-form-label">Add 3d model</label>
                  {/* <embed src={modelUrl} type="application/pdf" width="100%" height="250px" /> */}
                </div>
              ) : (
                <div className="form-group">
                  <label className="col col-form-label">Add 3d model</label>
                  <Dropzone
                    acceptFile={this.onFiles3dModel}
                    title={"Drag & Drop or Browse file"}
                  />
                </div>
              )}
              {modelUrl && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <strong>{modelUrl}</strong>
                  <button
                    type="button"
                    className="btn close"
                    aria-label="Close"
                    onClick={this.onDelete3dModel}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              )}
            </div>
            <div className="col-md-6">
              {carcaseUrl ? (
                <div className="">
                  <label className="col col-form-label">
                    Add img texture Reference
                  </label>
                  {/* <embed src={carcaseUrl} type="application/pdf" width="100%" height="250px" /> */}
                </div>
              ) : (
                <div className="form-group">
                  <label className="col col-form-label">
                    Add img texture Reference
                  </label>
                  <DropzoneImg
                    acceptFile={this.onCarcaseFileAdded}
                    title={"Drag & Drop or Browse file"}
                  />
                </div>
              )}
              {carcaseUrl && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <strong>{carcaseUrl}</strong>
                  <button
                    type="button"
                    className="btn close"
                    aria-label="Close"
                    onClick={this.onCarcaseDelete}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <button
                onClick={this.props.onCancel}
                type="button"
                className="btn btn-secondary btn-sm btn-block"
              >
                Cancel
              </button>
            </div>
            <div className="col-md-3" />
            <div className="col-md-3" />
            <div className="col-md-3">
              <button
                type="submit"
                className="btn btn-primary btn-sm btn-sm btn-block"
              >
                Save Changes
              </button>
            </div>
          </div>
        </form>
        {showLoader && <Loader />}
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          pauseOnHover
        />
      </React.Fragment>
    );
  }

  private onChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    onChange(this, name, value);
  };

  typeaheadOnChange = async (name: string, e: any) => {
    let value = e;
    if (e.length > 0 && e[0].customOption) {
      value = [{ name: e[0].name }];
    }
    onChange(this, name, value);
  };

  typeaheadOnChangeSelect = (name: string, e: any) => {
    let value = e;
    if (e.length > 0 && e[0].customOption) {
      value = [{ name: e[0].name }];
    }

    onChange(this, name, value);
  };

  onFiles3dModel = (files: any) => {
    var file = files[0];
    this.handleModelUpload(file);
  };

  handleModelUpload = (file: any) => {
    this.setState({ showLoader: true });

    const extArray = file.name.split(".");
    const ext = extArray[extArray.length - 1];
    const originalFilename = extArray[0];
    let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
    const fileDirectory = `${CONSTANT.fileDirectory.model3d}/${fileName}`;

    const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

    uploadTask.on(
      "state_changed",
      (snapshot: any) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      (error: any) => {
        console.log(error);
      },
      () => {
        firebase
          .app()
          .storage()
          .ref(CONSTANT.fileDirectory.model3d)
          .child(fileName)
          .getDownloadURL()
          .then((url) => {
            this.setState({
              modelUrl: url,
              modelFileName: fileName,
              showLoader: false,
            });
          });
      }
    );
  };

  onDelete3dModel = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete model?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result: any) => {
      if (result.value) {
        this.delete3dModel();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your catalogue is safe :)", "error");
      }
    });
  };

  delete3dModel = async () => {
    this.setState({ showLoader: true });
    try {
      let httpsReference = await firebase
        .storage()
        .refFromURL(this.state.modelUrl);

      firebase
        .app()
        .storage()
        .ref(CONSTANT.fileDirectory.model3d)
        .child(httpsReference.name)
        .delete()
        .then((url) => {
          this.setState({ modelUrl: undefined, showLoader: false });
          Swal.fire("Deleted!", "Model has been deleted.", "success");
        });
    } catch (e) {
      toast.error(e.message);
      this.setState({ showLoader: false });
    }
  };

  onCarcaseFileAdded = (e: any) => {
    var file = e[0];
    if (file) {
      this.handleCarcaseUpload(file);
    }
  };

  handleCarcaseUpload = (file: any) => {
    this.setState({ showLoader: true });

    const extArray = file.name.split(".");
    const ext = extArray[extArray.length - 1];
    const originalFilename = extArray[0];
    let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
    const fileDirectory = `${CONSTANT.fileDirectory.carcase}/${fileName}`;

    const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

    uploadTask.on(
      "state_changed",
      (snapshot: any) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      (error: any) => {
        console.log(error);
      },
      () => {
        firebase
          .app()
          .storage()
          .ref(CONSTANT.fileDirectory.carcase)
          .child(fileName)
          .getDownloadURL()
          .then((url) => {
            this.setState({
              carcaseUrl: url,
              carcaseFileName: fileName,
              showLoader: false,
            });
          });
      }
    );
  };

  onCarcaseDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete carcase?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result: any) => {
      if (result.value) {
        this.deleteCarcase();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your carcase is safe :)", "error");
      }
    });
  };

  deleteCarcase = async () => {
    this.setState({ showLoader: true });
    try {
      let httpsReference = await firebase
        .storage()
        .refFromURL(this.state.carcaseUrl);

      firebase
        .app()
        .storage()
        .ref(CONSTANT.fileDirectory.carcase)
        .child(httpsReference.name)
        .delete()
        .then((url) => {
          this.setState({ carcaseUrl: undefined, showLoader: false });
          Swal.fire("Deleted!", "Carcase has been deleted.", "success");
        });
    } catch (e) {
      toast.error(e.message);
      this.setState({ showLoader: false });
    }
  };

  onSubmit = (e: any) => {
    e.preventDefault();
    if (validateForm(this)) {
      let model: any = {
        description: this.state.description.value,
        // modelUrl: this.state.modelUrl,
        // textureUrl: this.state.carcaseUrl,
        meatProductId: this.state.meatProduct.value.map((i: any) => i.id),
      };
      if (this.state.id) {
        model.id = this.state.id;
      }
      this.props.onSubmit(model);
    }
  };
}

export default AddComponent;
