import * as React from "react";
import firebase from "firebase/app";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

import Loader from "../../component/common/Loader";
import Dropzone from "./../common/DropzoneModel";
import DropzoneImg from "./../common/DropzoneImg";
import { onChange, validateForm, getStorage } from "./../../utils";
import CONSTANT from "../../constant";
import { db } from "./../../firebase/index";

class AddComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    const detail = props.detail;
    this.state = {
      id: props.id,
      detail,
      currentUserId: undefined,
      showLoader: false,

      animalName: {
        name: "animalName",
        value: detail && detail.animalId ? detail.animalId : "",
        error: "",
        isRequired: true,
        options: [],
      },
      levelOne: {
        name: "levelOne",
        value: detail && detail.levelOneId ? detail.levelOneId : "",
        error: "",
        isRequired: true,
        options: [],
      },
      levelTwo: {
        name: "levelTwo",
        value: detail && detail.levelTwoId ? detail.levelTwoId : "",
        error: "",
        isRequired: true,
        options: [],
      },
      levelThree: {
        name: "levelThree",
        value: detail && detail.levelThreeId ? detail.levelThreeId : "",
        error: "",
        isRequired: false,
        options: [],
      },

      meatcutName: {
        name: "meatcutName",
        value: detail && detail.meatcutName ? detail.meatcutName : "",
        error: "",
        isRequired: true,
      },
      meatcutCode: {
        name: "meatcutCode",
        value: detail && detail.meatcutCode ? detail.meatcutCode : "",
        error: "",
        isRequired: true,
      },
      meatcutDetail: {
        name: "meatcutDetail",
        value: detail && detail.meatcutDetail ? detail.meatcutDetail : "",
        error: "",
        isRequired: true,
      },

      modelUrl: detail && detail.modelUrl ? detail.modelUrl : "",
      modelFileName: detail && detail.modelFileName ? detail.modelFileName : "",

      carcaseUrl: detail && detail.carcaseUrl ? detail.carcaseUrl : "",
      carcaseFileName:
        detail && detail.carcaseFileName ? detail.carcaseFileName : "",

      textureUrl: detail && detail.textureUrl ? detail.textureUrl : "",
      skeltonUrl: detail && detail.skeltonUrl ? detail.skeltonUrl : "",

      muscleRef: {
        name: "muscleRef",
        value: detail && detail.muscleRef ? detail.muscleRef : "",
        error: "",
        isRequired: true,
      },
      imageUrl: detail && detail.images ? detail.images : [],
    };
  }

  componentDidMount() {
    const ud = getStorage(CONSTANT.keys.userDetail);
    this.setState({ currentUserId: ud.uid });
    this.loadList();
  }

  public render() {
    const {
      showLoader,
      animalName,
      levelOne,
      levelTwo,
      meatcutName,
      meatcutCode,
      meatcutDetail,
      modelUrl,
      carcaseUrl,
      muscleRef,
      textureUrl,
      skeltonUrl,
      imageUrl,
    } = this.state;
    return (
      <React.Fragment>
        <form onSubmit={this.onSubmit}>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label className="col col-form-label">Animal Name</label>
                <div className="col-lg-12 form-group">
                  <select
                    className={
                      animalName.error.length > 0
                        ? "form-control is-invalid"
                        : "form-control mr-1"
                    }
                    name={animalName.name}
                    value={animalName.value}
                    onChange={this.onChange}
                  >
                    <option value="">Select animal</option>
                    {animalName.options.map(function (item: any, index: any) {
                      return (
                        <option key={index} id={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="col col-form-label">Level 1</label>
                <div className="col-lg-12 form-group">
                  <select
                    className={
                      levelOne.error.length > 0
                        ? "form-control is-invalid"
                        : "form-control mr-1"
                    }
                    name={levelOne.name}
                    value={levelOne.value}
                    onChange={this.onChange}
                  >
                    <option value="">Select level 1</option>
                    {levelOne.options.map(function (item: any, index: any) {
                      return (
                        <option key={index} id={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="col col-form-label">Level 2</label>
                <div className="col-lg-12 form-group">
                  <select
                    className={
                      levelTwo.error.length > 0
                        ? "form-control is-invalid"
                        : "form-control mr-1"
                    }
                    name={levelTwo.name}
                    value={levelTwo.value}
                    onChange={this.onChange}
                  >
                    <option value="">Select level 2</option>
                    {levelTwo.options.map(function (item: any, index: any) {
                      return (
                        <option key={index} id={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            {/* <div className="col-md-3">
                            <div className="form-group">
                                <label className="col col-form-label">Level 3</label>
                                <div className="col-lg-12 form-group">
                                    <select
                                        className={levelThree.error.length > 0 ? "form-control is-invalid" : "form-control mr-1"}
                                        name={levelThree.name}
                                        value={levelThree.value}
                                        onChange={this.onChange}>
                                        <option value="">Select level 3</option>
                                        {
                                            levelThree.options.map(function (item: any, index: any) {
                                                return (
                                                    <option key={index} id={item.id} value={item.id}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div> */}
          </div>

          <div className="row mt-2">
            <div className="col-lg-8">
              <div className="form-group row pl-2">
                <label className="col-2 col-form-label">Meatcut Name</label>
                <div className="col-10">
                  <input
                    type="text"
                    className={
                      meatcutName.error.length > 0
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    placeholder="Enter meatcut name"
                    name={meatcutName.name}
                    value={meatcutName.value}
                    onChange={this.onChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group row">
                <label className="col-5 col-form-label">Meatcut Code</label>
                <div className="col-lg-7 form-group">
                  <input
                    type="text"
                    className={
                      meatcutCode.error.length > 0
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    placeholder="Enter meatcut code"
                    name={meatcutCode.name}
                    value={meatcutCode.value}
                    onChange={this.onChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-4">
              <div className="form-group">
                <label className="col col-form-label">Meatcut Detail</label>
                <div className="col-lg-12 form-group">
                  <textarea
                    className={
                      meatcutDetail.error.length > 0
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    rows={6}
                    placeholder="Write text here"
                    name={meatcutDetail.name}
                    value={meatcutDetail.value}
                    onChange={this.onChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {modelUrl ? (
                <div className="">
                  <label className="col col-form-label">Add 3d model</label>
                  {/* <embed src={modelUrl} type="application/pdf" width="100%" height="250px" /> */}
                </div>
              ) : (
                <div className="form-group">
                  <label className="col col-form-label">Add 3d model</label>
                  <Dropzone
                    acceptFile={this.onFiles3dModel}
                    title={"Drag & Drop or Browse file"}
                  />
                </div>
              )}
              {modelUrl && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <strong>{modelUrl}</strong>
                  <button
                    type="button"
                    className="btn close"
                    aria-label="Close"
                    onClick={this.onDelete3dModel}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              )}
            </div>
            <div className="col-md-4">
              {textureUrl ? (
                <div className="">
                  <label className="col col-form-label">
                    Add texture image
                  </label>
                </div>
              ) : (
                <div className="form-group">
                  <label className="col col-form-label">
                    Add texture image
                  </label>
                  <DropzoneImg
                    acceptFile={this.onTextureFileAdded}
                    title={"Drag & Drop or Browse file"}
                  />
                </div>
              )}
              {textureUrl && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <strong>{textureUrl}</strong>
                  <button
                    type="button"
                    className="btn close"
                    aria-label="Close"
                    onClick={this.onDeleteTexture}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              )}
            </div>
            <div className="col-md-4">
              {carcaseUrl ? (
                <div className="">
                  <label className="col col-form-label">
                    Add muscle ref image
                  </label>
                </div>
              ) : (
                <div className="form-group">
                  <label className="col col-form-label">
                    Add muscle ref image
                  </label>
                  <DropzoneImg
                    acceptFile={this.onCarcaseFileAdded}
                    title={"Drag & Drop or Browse file"}
                  />
                </div>
              )}
              {carcaseUrl && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <strong>{carcaseUrl}</strong>
                  <button
                    type="button"
                    className="btn close"
                    aria-label="Close"
                    onClick={this.onCarcaseDelete}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              )}
            </div>
            <div className="col-md-4">
              {skeltonUrl ? (
                <div className="">
                  <label className="col col-form-label">
                    Add skeltion image
                  </label>
                </div>
              ) : (
                <div className="form-group">
                  <label className="col col-form-label">
                    Add skelton image
                  </label>
                  <DropzoneImg
                    acceptFile={this.onSkeltonFileAdded}
                    title={"Drag & Drop or Browse file"}
                  />
                </div>
              )}
              {skeltonUrl && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <strong>{skeltonUrl}</strong>
                  <button
                    type="button"
                    className="btn close"
                    aria-label="Close"
                    onClick={this.onDeleteSkelton}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              )}
            </div>
            <div className="col-md-4">
              {imageUrl && imageUrl.length > 0 ? (
                <div className="">
                  <label className="col col-form-label">Other Images</label>
                </div>
              ) : (
                <div className="form-group">
                  <label className="col col-form-label">Other Images</label>
                  <DropzoneImg
                    acceptFile={this.onFilesSelect}
                    title={"Drag & Drop or Browse file or click to add file"}
                  />
                </div>
              )}
              {imageUrl &&
                imageUrl.length > 0 &&
                imageUrl.map((item: any, index: any) => {
                  return (
                    <div
                      className="alert alert-success alert-dismissible fade show"
                      role="alert"
                      key={index}
                    >
                      <strong>{item}</strong>
                      <button
                        type="button"
                        className="btn close"
                        aria-label="Close"
                        onClick={() => this.onDeleteOtherImage(item)}
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="row col-12">
                <div className="col-lg-10 form-group">
                  <div className="col-lg-4">
                    <div className="form-group row">
                      <label className="col-5 col-form-label">Muscle ref</label>
                      <div className="col-lg-7 form-group">
                        <input
                          type="text"
                          className={
                            muscleRef.error.length > 0
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          placeholder="Enter muscle ref code"
                          name={muscleRef.name}
                          value={muscleRef.value}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 ml-0 mt-1">
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm btn-block"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        {showLoader && <Loader />}
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          pauseOnHover
        />
      </React.Fragment>
    );
  }

  private onChange = (e: any) => {
    const name = e.target.name;
    let value = e.target.value;

    onChange(this, name, value);
  };

  typeaheadOnChange = (name: string, e: any) => {
    let value = e;
    if (e.length > 0 && e[0].customOption) {
      value = [{ name: e[0].name }];
    }
    onChange(this, name, value);
  };

  loadList = async () => {
    this.setState({ showLoader: true });
    const animalListArray: any[] = [];
    const levelOneListArray: any[] = [];
    const levelTwoListArray: any[] = [];
    const levelThreeListArray: any[] = [];

    const animalQuerySnapshot = await db
      .collection(CONSTANT.collection.cmsCategoryAnimal)
      .where("isActive", "==", true)
      .orderBy("modifiedOn", "asc")
      .get();
    animalQuerySnapshot.forEach((doc: any) => {
      let data = doc.data();
      data.id = doc.id;
      animalListArray.push(data);
    });

    const levelOneQuerySnapshot = await db
      .collection(CONSTANT.collection.cmsCategoryLevel1)
      .where("isActive", "==", true)
      .orderBy("modifiedOn", "asc")
      .get();
    levelOneQuerySnapshot.forEach((doc: any) => {
      let data = doc.data();
      data.id = doc.id;
      levelOneListArray.push(data);
    });

    const levelTwoQuerySnapshot = await db
      .collection(CONSTANT.collection.cmsCategoryLevel2)
      .where("isActive", "==", true)
      .orderBy("modifiedOn", "asc")
      .get();
    levelTwoQuerySnapshot.forEach((doc: any) => {
      let data = doc.data();
      data.id = doc.id;
      levelTwoListArray.push(data);
    });

    const levelThreeQuerySnapshot = await db
      .collection(CONSTANT.collection.cmsCategoryLevel3)
      .where("isActive", "==", true)
      .orderBy("modifiedOn", "asc")
      .get();
    levelThreeQuerySnapshot.forEach((doc: any) => {
      let data = doc.data();
      data.id = doc.id;
      levelThreeListArray.push(data);
    });

    this.setState({
      showLoader: false,
      animalName: { ...this.state.animalName, options: animalListArray },
      levelOne: { ...this.state.levelOne, options: levelOneListArray },
      levelTwo: { ...this.state.levelTwo, options: levelTwoListArray },
      levelThree: { ...this.state.levelThree, options: levelThreeListArray },
    });
  };

  onFiles3dModel = (files: any) => {
    var file = files[0];
    this.handleModelUpload(file);
  };

  handleModelUpload = (file: any) => {
    this.setState({ showLoader: true });

    const extArray = file.name.split(".");
    const ext = extArray[extArray.length - 1];
    const originalFilename = extArray[0];
    let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
    const fileDirectory = `${CONSTANT.fileDirectory.model3d}/${fileName}`;

    const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

    uploadTask.on(
      "state_changed",
      (snapshot: any) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      (error: any) => {
        console.log(error);
      },
      () => {
        firebase
          .app()
          .storage()
          .ref(CONSTANT.fileDirectory.model3d)
          .child(fileName)
          .getDownloadURL()
          .then((url) => {
            this.setState({
              modelUrl: url,
              modelFileName: fileName,
              showLoader: false,
            });
          });
      }
    );
  };

  onDelete3dModel = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete model?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result: any) => {
      if (result.value) {
        this.delete3dModel();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your catalogue is safe :)", "error");
      }
    });
  };

  delete3dModel = () => {
    this.setState({ showLoader: true });
    firebase
      .app()
      .storage()
      .ref(CONSTANT.fileDirectory.model3d)
      .child(this.state.modelFileName)
      .delete()
      .then((url) => {
        this.setState({ modelFileName: "", modelUrl: "", showLoader: false });
        Swal.fire("Deleted!", "Model has been deleted.", "success");
      });
  };

  onCarcaseFileAdded = (e: any) => {
    var file = e[0];
    if (file) {
      this.handleCarcaseUpload(file);
    }
  };

  onTextureFileAdded = (e: any) => {
    var file = e[0];
    if (file) {
      this.handleTextureUpload(file);
    }
  };

  handleTextureUpload = (file: any) => {
    this.setState({ showLoader: true });

    const extArray = file.name.split(".");
    const ext = extArray[extArray.length - 1];
    const originalFilename = extArray[0];
    let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
    const fileDirectory = `${CONSTANT.fileDirectory.texture}/${fileName}`;

    const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

    uploadTask.on(
      "state_changed",
      (snapshot: any) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      (error: any) => {
        console.log(error);
      },
      () => {
        firebase
          .app()
          .storage()
          .ref(CONSTANT.fileDirectory.texture)
          .child(fileName)
          .getDownloadURL()
          .then((url) => {
            this.setState({ textureUrl: url, showLoader: false });
          });
      }
    );
  };

  onDeleteTexture = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete texture?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result: any) => {
      if (result.value) {
        this.deleteTexture();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your texture is safe :)", "error");
      }
    });
  };

  deleteTexture = async () => {
    this.setState({ showLoader: true });
    try {
      let httpsReference = await firebase
        .storage()
        .refFromURL(this.state.modelUrl);

      firebase
        .app()
        .storage()
        .ref(CONSTANT.fileDirectory.texture)
        .child(httpsReference.name)
        .delete()
        .then((url) => {
          this.setState({ textureUrl: undefined, showLoader: false });
          Swal.fire("Deleted!", "Texture has been deleted.", "success");
        });
    } catch (e) {
      toast.error(e.message);
      this.setState({ showLoader: false });
    }
  };

  handleCarcaseUpload = (file: any) => {
    this.setState({ showLoader: true });

    const extArray = file.name.split(".");
    const ext = extArray[extArray.length - 1];
    const originalFilename = extArray[0];
    let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
    const fileDirectory = `${CONSTANT.fileDirectory.carcase}/${fileName}`;

    const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

    uploadTask.on(
      "state_changed",
      (snapshot: any) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      (error: any) => {
        console.log(error);
      },
      () => {
        firebase
          .app()
          .storage()
          .ref(CONSTANT.fileDirectory.carcase)
          .child(fileName)
          .getDownloadURL()
          .then((url) => {
            this.setState({
              carcaseUrl: url,
              carcaseFileName: fileName,
              showLoader: false,
            });
          });
      }
    );
  };

  onCarcaseDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete carcase?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result: any) => {
      if (result.value) {
        this.deleteCarcase();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your carcase is safe :)", "error");
      }
    });
  };

  deleteCarcase = () => {
    this.setState({ showLoader: true });
    firebase
      .app()
      .storage()
      .ref(CONSTANT.fileDirectory.carcase)
      .child(this.state.carcaseFileName)
      .delete()
      .then((url) => {
        this.setState({
          carcaseFileName: "",
          carcaseUrl: "",
          showLoader: false,
        });
        Swal.fire("Deleted!", "Carcase has been deleted.", "success");
      });
  };

  onSkeltonFileAdded = (e: any) => {
    var file = e[0];
    if (file) {
      this.handleSkeltonUpload(file);
    }
  };

  handleSkeltonUpload = (file: any) => {
    this.setState({ showLoader: true });

    const extArray = file.name.split(".");
    const ext = extArray[extArray.length - 1];
    const originalFilename = extArray[0];
    let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
    const fileDirectory = `${CONSTANT.fileDirectory.skelton}/${fileName}`;

    const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

    uploadTask.on(
      "state_changed",
      (snapshot: any) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progress });
      },
      (error: any) => {
        console.log(error);
      },
      () => {
        firebase
          .app()
          .storage()
          .ref(CONSTANT.fileDirectory.skelton)
          .child(fileName)
          .getDownloadURL()
          .then((url) => {
            this.setState({ skeltonUrl: url, showLoader: false });
          });
      }
    );
  };

  onDeleteSkelton = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete skelton?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result: any) => {
      if (result.value) {
        this.deleteskelton();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your skelton is safe :)", "error");
      }
    });
  };

  deleteskelton = async () => {
    this.setState({ showLoader: true });
    try {
      let httpsReference = await firebase
        .storage()
        .refFromURL(this.state.skeltonUrl);

      firebase
        .app()
        .storage()
        .ref(CONSTANT.fileDirectory.skelton)
        .child(httpsReference.name)
        .delete()
        .then((url) => {
          this.setState({ skeltonUrl: undefined, showLoader: false });
          Swal.fire("Deleted!", "Skelton has been deleted.", "success");
        });
    } catch (e) {
      toast.error(e.message);
      this.setState({ showLoader: false });
    }
  };

  onFilesSelect = (files: any) => {
    var file = files;

    this.handleOtherImageUpload(file);
  };

  handleOtherImageUpload = async (fileArray: any) => {
    this.setState({ showLoader: true });

    const promises = fileArray.map((file: any) => {
      const extArray = file.name.split(".");
      const ext = extArray[extArray.length - 1];
      const originalFilename = extArray[0];
      let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
      const fileDirectory = `${CONSTANT.fileDirectory.inventory}/${fileName}`;
      const ref = firebase.storage().ref().child(`${fileDirectory}`);
      return ref.put(file.uploadTask).then(() => ref.getDownloadURL());
    });

    await Promise.all(promises)
      .then((fileDownloadUrls) => {
        this.setState({ imageUrl: fileDownloadUrls, showLoader: false });
      })
      .catch((err) => console.log(err));
    this.setState({ showLoader: false });
  };

  onDeleteOtherImage(item: any) {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete image?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result: any) => {
      if (result.value) {
        this.deleteOtherImg(item);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your image is safe :)", "error");
      }
    });
  }

  deleteOtherImg = async (item: any) => {
    this.setState({ showLoader: true });
    try {
      var httpsReference = await firebase.storage().refFromURL(item);

      firebase
        .app()
        .storage()
        .ref(CONSTANT.fileDirectory.inventory)
        .child(httpsReference.name)
        .delete()
        .then((url) => {
          let newarr = this.state.imageUrl.filter((a: any) => a !== item);
          this.setState({ imageUrl: newarr, showLoader: false });
          Swal.fire("Deleted!", "Image has been deleted.", "success");
        });
    } catch (e) {
      toast.error(e.message);
      this.setState({ showLoader: false });
    }
  };

  onSubmit = (e: any) => {
    e.preventDefault();
    if (validateForm(this)) {
      const {
        animalName,
        levelOne,
        levelTwo,
        levelThree,
        meatcutName,
        meatcutCode,
        meatcutDetail,
        modelUrl,
        carcaseUrl,
        muscleRef,
        modelFileName,
        carcaseFileName,
        textureUrl,
        skeltonUrl,
        imageUrl,
      } = this.state;

      // if (!modelUrl) return toast.error("Please upload 3d model");
      // if (!textureUrl) return toast.error("Please upload textureUrl image");
      // if (!carcaseUrl) return toast.error("Please upload carcase image");
      // if (!skeltonUrl) return toast.error("Please upload skelton image");

      const model: any = {
        animalId: animalName.value,
        levelOneId: levelOne.value,
        levelTwoId: levelTwo.value,
        levelThreeId: levelThree.value,
        meatcutName: meatcutName.value,
        meatcutCode: meatcutCode.value,
        meatcutDetail: meatcutDetail.value,
        modelUrl: modelUrl,
        carcaseUrl: carcaseUrl,
        muscleRef: muscleRef.value,
        modelFileName: modelFileName,
        carcaseFileName: carcaseFileName,
        textureUrl: textureUrl,
        skeltonUrl: skeltonUrl,
        otherImages: imageUrl,
      };
      if (this.state.id) {
        model.id = this.state.id;
      }
      this.props.onSubmit(model);
    }
  };
}

export default AddComponent;
