import * as React from 'react';
import firebase from "firebase/app";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2/dist/sweetalert2'
import 'sweetalert2/src/sweetalert2.scss';

import Loader from '../../component/common/Loader';
import { onChange, validateForm } from '../../utils';
import DropzoneImg from './../common/DropzoneImg';
import CONSTANT from '../../constant';

class AddComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        this.state = {
            id: detail ? detail.id : undefined,
            name: { name: 'name', value: detail ? detail.name : '', error: '', isRequired: true },
            imageUrl: detail && detail.imageUrl ? detail.imageUrl : '',
        }
    }

    public render() {
        const { showLoader, name, imageUrl } = this.state;
        return (
            <React.Fragment>
                <form onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-lg-12 form-group">
                            <input
                                type="text"
                                className={name.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                placeholder="Enter new animal"
                                name={name.name}
                                value={name.value}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="col-lg-12">
                        {imageUrl ?
                            <div className="">
                                <label className="col col-form-label">Image</label>
                            </div>
                            :
                            <div className="form-group">
                                <label className="col col-form-label ml-0">Image</label>
                                <DropzoneImg
                                    acceptFile={this.onFilesProductImage}
                                    title={"Drag & Drop or Browse file"}
                                />
                            </div>
                        }
                        {imageUrl &&
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                <strong>{imageUrl}</strong>
                                <button type="button" className="btn close" aria-label="Close" onClick={this.onDeleteProductImage}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                        }
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <button onClick={this.props.onCancel} type="button" className="btn btn-secondary btn-sm btn-block">Cancel</button>
                        </div>
                        <div className="col-md-3" />
                        <div className="col-md-3" />
                        <div className="col-md-3">
                            <button type="submit" className="btn btn-primary btn-sm btn-sm btn-block">Save Changes</button>
                        </div>
                    </div>
                </form>
                {
                    showLoader && <Loader />
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    onFilesProductImage = (files: any) => {
        var file = files[0];
        this.handleProductImageUpload(file);
    }

    handleProductImageUpload = (file: any) => {
        this.setState({ showLoader: true });

        const extArray = file.name.split('.');
        const ext = extArray[extArray.length - 1];
        const originalFilename = extArray[0];
        let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
        const fileDirectory = `${CONSTANT.fileDirectory.animalImage}/${fileName}`;

        const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

        uploadTask.on("state_changed", (snapshot: any) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.setState({ progress });
        },
            (error: any) => {
                console.log(error);
            },
            () => {
                firebase.app().storage()
                    .ref(CONSTANT.fileDirectory.animalImage)
                    .child(fileName)
                    .getDownloadURL()
                    .then(url => {
                        this.setState({ imageUrl: url, showLoader: false });
                    });
            }
        );
    };

    onDeleteProductImage = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete image?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result: any) => {
            if (result.value) {
                this.deleteProductImage();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your image is safe :)',
                    'error'
                )
            }
        })
    }

    deleteProductImage = async () => {
        this.setState({ showLoader: true });
        try {
            var httpsReference = await firebase.storage().refFromURL(this.state.imageUrl);
            firebase.app().storage()
                .ref(CONSTANT.fileDirectory.animalImage)
                .child(httpsReference.name)
                .delete()
                .then(url => {
                    this.setState({ imageUrl: "", showLoader: false });
                    Swal.fire(
                        'Deleted!',
                        'Image has been deleted.',
                        'success'
                    )
                });
        } catch (e) {
            toast.error(e.message);
            this.setState({ showLoader: false });
        }
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            if (!this.state.imageUrl) return toast.error("Please upload animal image.");
            let model: any = {
                name: this.state.name.value,
                nameFlag: this.state.name.value.toLowerCase(),
                imageUrl: this.state.imageUrl
            }
            if (this.state.id) {
                model.id = this.state.id;
            }
            this.props.onSubmit(model);
        }
    }

}

export default AddComponent;