import * as React from 'react';
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Header from './../../component/common/Header';
import SubHeader from './../../component/common/SubHeader';
import Sidebar from './../../component/common/Sidebar';
import Footer from './../../component/common/Footer';
import Loader from '../../component/common/Loader';
import ModalWindow from './../../component/common/ModalWindow';
import ModelDetailComponent from './../../component/model/ModelDetail';
import ModelPointDetailComponent from './../../component/model/PointDetail';
import CONSTANT from './../../constant';
import { db } from './../../firebase/index';
import { onChange } from './../../utils';

const style = {
    height: 1200, // we can control scene size by setting container dimensions,
    width: 720
};

class Scene extends React.Component<any, any> {
    controls: any;
    mount: any;
    requestID: any;
    scene: any;
    camera: any;
    renderer: any;
    model: any;
    raycaster: any;
    mouse: any;
    frameId: any;
    mesh: any;

    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false,
            userCount: undefined,
            meatcutCount: undefined,
            primalMeatcutCount: undefined,
            isMounted: true,
            show3dModal: false,
            modelDetail: undefined,
            show3dModalPoint: false,
            modelPointDetail: undefined,
            search: { name: 'search', value: '', options: [], error: '', isRequired: true },
            isModelRender: false,
            modelUrl: 'https://threejsfundamentals.org/threejs/resources/models/windmill/windmill.obj',
            model3dDetail: undefined,
            intersectionUvPoint: undefined,
            intersectionPoint: undefined,
            modelRation: undefined,
            distance: undefined,
            value: ''
        }
    }

    componentDidMount() {
        if (this.state.model3dDetail) {
            this.loadTheModel();
        }
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
        if (this.state.model3dDetail) {
            window.cancelAnimationFrame(this.requestID);
        }
    }

    public render() {
        const { showLoader, show3dModal, show3dModalPoint, modelDetail, modelPointDetail } = this.state;
        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <Sidebar />
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <Header />
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                                <SubHeader headerTitle="3d Model Management" seperatorTitle="" />
                                {this.renderBody()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
                {
                    show3dModal && <ModalWindow
                        className="modal-lg"
                        title="Add New 3d Model"
                        backdrop="static"
                        toggleModal={this.onCancel}>
                        <ModelDetailComponent
                            onSubmit={this.onSubmitModel}
                            onCancel={this.onCancel}
                            detail={modelDetail}
                        />
                    </ModalWindow>
                }
                {
                    show3dModalPoint && <ModalWindow
                        className="modal-lg"
                        title="Add Point on 3d Model"
                        backdrop="static"
                        toggleModal={this.onCancel}>
                        <ModelPointDetailComponent
                            onSubmit={this.onSubmitModelPoint}
                            onCancel={this.onCancel}
                            detail={modelPointDetail}
                        />
                    </ModalWindow>
                }
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    renderBody = () => {
        const { search, model3dDetail } = this.state;
        return (
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                <div className="row">
                    <div className="col-md-12">

                        <div className="row">
                            <div className="col-md-9">
                                <div className="form-group">
                                    <Typeahead
                                        id="ta-skills"
                                        allowNew={false}
                                        labelKey='name'
                                        name={search.name}
                                        selected={search.value}
                                        multiple={false}
                                        options={search.options}
                                        onChange={(e: any) => this.typeaheadOnChangeSelect(search.name, e)}
                                        onInputChange={(e: any) => this.typeaheadOnChange(search.name, e)}
                                        placeholder="Search and select 3d model"
                                        isInvalid={search.error.length > 0}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <button type="button" className="btn btn-block btn-info" onClick={this.addModal}>Add New 3d Model</button>
                            </div>
                        </div>

                        <div className="row text-center" >
                            <div className="col-md-12">
                                <div className="kt-portlet kt-portlet--height-fluid">
                                    <div className="kt-portlet__body kt-portlet__body--fit">
                                        {model3dDetail &&
                                            <div id='render' style={style} ref={ref => (this.mount = ref)} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    }

    typeaheadOnChange = async (name: string, e: any) => {
        let value = e;
        if (e.length > 0 && e[0].customOption) {
            value = [{ name: e[0].name }];
        }

        let result: any[] = [];
        const querySnapshot = await db.collection(CONSTANT.collection.model3d).where('isActive', '==', true).orderBy('nameFlag').startAt(`${value}`).endAt(value + '\uf8ff').get();
        querySnapshot.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            result.push(data);
        });

        this.setState({
            search: { ...this.state.search, options: result, model3dDetail: undefined },
        });
    }

    typeaheadOnChangeSelect = (name: string, e: any) => {
        let value = e;
        let url: any = undefined;
        let detail = undefined;
        if (e.length > 0) {
            value = [{ name: e[0].name }];
            url = e[0].modelUrl;
            detail = e[0];
        }

        onChange(this, name, value);
        this.setState({ isModelRender: true, modelUrl: url, model3dDetail: detail }, () => {
            if (this.state.modelUrl) {
                this.loadTheModel();
            }
        });
    }

    addModal = () => {
        this.setState({ show3dModal: true });
    }


    onCancel = () => {
        this.setState({ show3dModal: false, show3dModalPoint: false });
    }

    distanceVector(v1: any, v2: any) {
        var dx = v1.x - v2.x;
        var dy = v1.y - v2.y;
        var dz = v1.z - v2.z;

        return Math.sqrt(dx * dx + dy * dy + dz * dz);
    }

    // OBJ Loader
    loadTheModel = async () => {
        const { modelUrl } = this.state;

        // get container dimensions and use them for scene sizing
        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;

        this.scene = await new THREE.Scene();
        this.camera = await new THREE.PerspectiveCamera(
            60, // fov = field of view
            width / height, // aspect ratio
            0.1, // near plane
            1000 // far plane
        );

        this.camera.position.set(0, 0, -10);
        // this.camera.position.z = 500; // is used here to set some distance from a cube that is located at z = 0

        // OrbitControls allow a camera to orbit around the object
        this.controls = new OrbitControls(this.camera, this.mount);
        this.renderer = new THREE.WebGLRenderer();
        this.renderer.setSize(width, height);
        this.mount.appendChild(this.renderer.domElement); // mount using React ref


        const lights = [];
        // set color and intensity of lights
        lights[0] = new THREE.PointLight(0xffffff, 1, 0);
        lights[1] = new THREE.PointLight(0xffffff, 1, 0);
        lights[2] = new THREE.PointLight(0xffffff, 1, 0);

        // place some lights around the scene for best looks and feel
        lights[0].position.set(0, 2000, 0);
        lights[1].position.set(1000, 2000, 1000);
        lights[2].position.set(- 1000, - 2000, - 1000);

        this.scene.add(lights[0]);
        this.scene.add(lights[1]);
        this.scene.add(lights[2]);


        // slowly rotate an object
        if (this.model) this.model.rotation.z += 0.005;

        this.renderer.render(this.scene, this.camera);

        // The window.requestAnimationFrame() method tells the browser that you wish to perform
        // an animation and requests that the browser call a specified function
        // to update an animation before the next repaint
        this.requestID = window.requestAnimationFrame(this.startAnimationLoop);


        // instantiate a loader
        const loader = new OBJLoader();
        // load a resource
        loader.load(`${modelUrl}`, (object: any) => {
            this.mesh = object;
            this.mesh.position.set(0, 0, 0);
            this.mesh.scale.set(0.05, 0.05, 0.05);

            this.scene.add(object);
        }, (xhr: any) => {
            const loadingPercentage = Math.ceil(xhr.loaded / xhr.total * 100);
            console.log((loadingPercentage) + '% loaded');
        }, (error: any) => {
            console.log('An error happened:' + error);
        });

        // Add raycaster to for interactivity
        this.raycaster = new THREE.Raycaster();
        this.mouse = new THREE.Vector2();

        this.renderer.domElement.addEventListener('click', onClick.bind(this), false);

        function onClick(this: any, event: any) {
            event.preventDefault();

            this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

            this.raycaster.setFromCamera(this.mouse, this.camera);

            var intersects = this.raycaster.intersectObjects(this.scene.children, true);

            if (intersects.length > 0) {
                const distance = this.distanceVector(this.camera.position, this.mesh.position);
                this.setState({
                    show3dModalPoint: true,
                    intersectionPoint: {
                        x: intersects[0].point.x,
                        y: intersects[0].point.y,
                    },
                    distance: distance,
                    modelRation: {
                        x: event.offsetX,
                        y: event.offsetY
                    }
                });
            }
        }
    };

    startAnimationLoop = () => {
        // slowly rotate an object
        if (this.model) this.model.rotation.z += 0.005;

        this.renderer.render(this.scene, this.camera);

        // The window.requestAnimationFrame() method tells the browser that you wish to perform
        // an animation and requests that the browser call a specified function
        // to update an animation before the next repaint
        this.requestID = window.requestAnimationFrame(this.startAnimationLoop);
    };

    handleWindowResize = () => {
        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;

        this.renderer.setSize(width, height);
        this.camera.aspect = width / height;

        // Note that after making changes to most of camera properties you have to call
        // .updateProjectionMatrix for the changes to take effect.
        this.camera.updateProjectionMatrix();
    };

    onSubmitModel = async (reqObj: any) => {
        reqObj.modifiedOn = moment().unix();
        reqObj.isActive = true;
        this.onCancel();
        this.setState({ showLoader: true });
        let arrTempName: any = [];
        let modelData: any = await db.collection(CONSTANT.collection.model3d).where("nameFlag", "==", reqObj.nameFlag.toLowerCase()).get();
        modelData.forEach((doc: any) => {
            let data = doc.data();
            data.id = doc.id;
            arrTempName.push(data);
        });
        if (reqObj.id) {

        } else {
            if (arrTempName.length > 0) {
                this.setState({ showLoader: false });
                toast.error('Model name already exist');
                return;
            } else {
                await db.collection(CONSTANT.collection.model3d).add(reqObj);
            }
        }
        this.setState({ showLoader: false });
    }

    onSubmitModelPoint = async (reqObj: any) => {
        this.onCancel();
        this.setState({ showLoader: true });

        reqObj.modifiedOn = moment().unix();
        reqObj.isActive = true;
        reqObj.modelId = this.state.model3dDetail.id
        reqObj.point = this.state.intersectionPoint;
        reqObj.distance = this.state.distance;
        reqObj.modelRation = this.state.modelRation;
        reqObj.screenRation = style;

        if (reqObj.id) {

        } else {
            await db.collection(CONSTANT.collection.model3dPoint).add(reqObj);
        }
        this.setState({ showLoader: false });
    }

}

export default Scene;