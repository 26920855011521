import { getStorage } from '../../utils';
import CONSTANT from '../../constant';

const ComponentName = () => {
    return (
        <div id="kt_header" className="kt-header kt-grid__item  kt-header--fixed" style={{ borderBottom: '2px solid #90caf9' }}>
            <div className="kt-header-menu-wrapper" id="kt_header_menu_wrapper">

            </div>
            <div className="kt-header__topbar">
                {userBar()}
            </div>
        </div>
    )
}

function userBar() {
    const ud = getStorage(CONSTANT.keys.userDetail);
    return (
        <div className="kt-header__topbar-item kt-header__topbar-item--user">
            <div className="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="0px,0px">
                <div className="kt-header__topbar-user">
                    <div className="avatar avatar-xl">
                        {ud && ud.profileUrl ?
                            <img className="rounded-circle" src={ud.profileUrl} alt="" />
                            : <img className="rounded-circle" src="/assets/media/users/default.jpg" alt="" />
                        }
                    </div>
                    <span className="kt-header__topbar-username kt-hidden-mobile ml-2">{ud && ud.displayName ? ud.displayName : ''}</span>
                </div>
            </div>
            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
                <div className="kt-notification">
                    <a href={CONSTANT.url.profile} className="kt-notification__item">
                        <div className="kt-notification__item-icon">
                            <i className="flaticon2-calendar-3 kt-font-success"></i>
                        </div>
                        <div className="kt-notification__item-details">
                            <div className="kt-notification__item-title kt-font-bold">
                                My Profile
                            </div>
                        </div>
                    </a>
                    <div className="kt-notification__custom kt-space-between">
                        <a href={CONSTANT.url.logout} className="btn btn-label btn-label-brand btn-sm btn-bold">Sign Out</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComponentName;