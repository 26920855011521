import * as React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "./../../../component/common/Header";
import Sidebar from "./../../../component/common/Sidebar";
import Footer from "./../../../component/common/Footer";
import Loader from "./../../../component/common/Loader";
import SubHeader from "../../../component/common/SubHeader";
import AddIndustryComponentDetail from "./../../../component/cms-pages/industryOverviewDetail";
import CONSTANT from "./../../../constant";
import { db } from "./../../../firebase/index";

class EditMarketing extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      id: props.match.params.id,
      detail: undefined,
      showLoader: false,
    };
  }

  componentDidMount() {
    this.loadList();
  }

  public render() {
    const { showLoader } = this.state;
    return (
      <React.Fragment>
        {showLoader && <Loader />}
        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <Sidebar />
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
              id="kt_wrapper"
            >
              <Header />
              <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <SubHeader headerTitle="CMS Pages" seperatorTitle="" />
                {this.renderBody()}
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          pauseOnHover
        />
      </React.Fragment>
    );
  }

  renderBody = () => {
    const { detail, id } = this.state;
    return (
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div className="row">
          <div className="kt-portlet">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">
                  Edit Marketing Research & Development
                </h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              {detail && Object.keys(detail).length > 0 && (
                <AddIndustryComponentDetail
                  onSubmit={this.onSubmit}
                  id={id}
                  detail={detail}
                />
              )}
              {detail && Object.keys(detail).length === 0 && (
                <div className="col-lg-12 text-center">
                  <span className="text-center">No records Found</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  loadList = async () => {
    const { id } = this.state;
    this.setState({ showLoader: true });
    const document = await db
      .collection(CONSTANT.collection.industryOverview)
      .doc(`${id}`)
      .get();
    if (!document.exists) return toast.error("about us not found.");
    let listData = document.data();
    this.setState({ detail: listData, showLoader: false });
  };

  onSubmit = async (model: any) => {
    const { id } = this.state;
    delete model.id;
    this.setState({ showLoader: true });
    const user = await db
      .collection(CONSTANT.collection.industryOverview)
      .doc(`${id}`)
      .get();
    if (!user.exists) return toast.error("about us not found.");

    await db
      .collection(CONSTANT.collection.industryOverview)
      .doc(`${id}`)
      .set(model, { merge: true });
    this.setState({ showLoader: false });
    window.location.href = CONSTANT.url.marketingResearch;
  };
}

export default EditMarketing;
