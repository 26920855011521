import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import firebase from "firebase/app";

import LoginBG from './../../assets/images/bg_login_1920.png';
import Loader from './../../component/common/Loader';
import { onChange, validateForm } from './../../utils';
import CONSTANT from './../../constant';

class ForgotPassword extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            email: { name: 'email', value: '', error: '', isRequired: true },
            showLoader: false
        };
    }

    public render() {
        const { email, showLoader } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && <Loader />
                }
                <div className="kt-grid kt-grid--ver kt-grid--root">
                    <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v1" id="kt_login">
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                            <div className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside" style={{ backgroundImage: `url(${LoginBG})` }}>
                                <div className="kt-grid__item">
                                    {/* <a href="#" className="kt-login__logo">
                                        <img src={Logo4} />
                                    </a> */}
                                </div>
                                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">

                                </div>
                                <div className="kt-grid__item">
                                    <div className="kt-login__info">
                                        <div className="kt-login__copyright">
                                            Copyright © {new Date().getFullYear()} <b>Aus Meat</b>. All rights reserved.
								        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
                                <div className="kt-login__body">

                                    <div className="kt-login__form">
                                        <div className="kt-login__title">
                                            <h3>Forgot password</h3>
                                        </div>
                                        <form className="kt-form" onSubmit={this.submitForm}>
                                            <div className="form-group">
                                                <input
                                                    className={email.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    type="email"
                                                    placeholder="Email address"
                                                    name={email.name}
                                                    value={email.value}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                            <div className="kt-login__actions">
                                                <a href={CONSTANT.url.login} className="kt-link kt-login__link-forgot">
                                                    Back to Login
										        </a>
                                                <button id="kt_login_signin_submit" className="btn btn-primary btn-elevate kt-login__btn-primary">Submit</button>
                                            </div>
                                        </form>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover
                />
            </React.Fragment>
        )
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
    }

    private submitForm = async (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const st = this.state;
            onChange(this, st.email.name, '');
            const model = {
                email: st.email.value
            };
            this.setState({ showLoader: true });
            firebase.app().auth().sendPasswordResetEmail(model.email).then((user: any) => {
                this.setState({ showLoader: false });
                toast.success("Email has been sent to your register email.");
            }).catch((error: any) => {
                this.setState({ showLoader: false });
                if (error && error.code === "auth/user-not-found") {
                    toast.error(error.message);
                }
                if (error && error.code === "auth/invalid-email") {
                    toast.error(error.message);
                }
            });
        }
    }
}

export default ForgotPassword;