import React, { useEffect, useState, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    padding: '50px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#d8e2ef',
    borderStyle: 'dashed',
    color: '#5e6e82',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const labelContainer = {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flexWrap: 'wrap' as 'wrap',
    marginTop: 16
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};


function StyledDropzone(props: any) {
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        accept: ".obj",
        onDrop: (acceptedFiles: any) => {
            setFiles(acceptedFiles.map((file: any) => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            props.acceptFile(acceptedFiles);
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    const [files, setFiles] = useState([]);

    const thumbs = files.map((file: any) => (
        <React.Fragment>
            <div key={file.name}>
                <div style={thumbInner}>
                    <div>{file.name}</div> <br />
                </div>
            </div>
        </React.Fragment>
    ));

    useEffect(() => () => {
        files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <div>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <img className="mr-2" src="/assets/img/icons/cloud-upload.svg" width="25" alt="" /><p>{props.title}</p>
            </div>
            <aside style={labelContainer}>
                {thumbs}
            </aside>
        </div>
    );
}


export default StyledDropzone;